import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../auth/login";
import { useAppSelector } from "../../../application/store/useStore";
import Board from "../admin/boards";
import ProtectedRoute from "./ProtectedRoutes";
import Layout from "./Layout";
import AddBoards from "../admin/boards/add-board";
import Schools from "../admin/schools";
import AddSchool from "../admin/schools/add-school";
import Teachers from "../admin/teachers";
import AddTeacher from "../admin/teachers/add-teacher";
import Goals from "../admin/goals";
import AddGoal from "../admin/goals/add-goal";
import SubGoals from "../admin/sub-goals";
import AddSubGoal from "../admin/sub-goals/add-sub-goal";
import Themes from "../admin/themes";
import AddTheme from "../admin/themes/add-theme";
import AuthLayout from "./AuthLayout";
import EditBoards from "../admin/boards/edit-board";
import EditSubGoal from "../admin/sub-goals/edit-sub-goal";
import EditGoal from "../admin/goals/edit-goal";
import EditTeacher from "../admin/teachers/edit-teacher";
import EditTheme from "../admin/themes/edit-theme";
import Lessons from "../admin/lessons";
import AddLesson from "../admin/lessons/add-lesson";
import EditSchool from "../admin/schools/edit-school";
import TeachersList from "../admin/schools/teachers";
import EditLesson from "../admin/lessons/edit-lesson";
import Vouchers from "../admin/vouchers";
import AddVoucher from "../admin/vouchers/add-voucher";
import News from "../admin/news";
import AddNews from "../admin/news/add-news";
import EditNews from "../admin/news/edit-news";
import Agreements from "../admin/agreements";
import AddAgreement from "../admin/agreements/add-agreement";
import EditAgreement from "../admin/agreements/edit-agreement";
import SubGoalsList from "../admin/goals/sub-goal-list";
import SchoolList from "../admin/boards/schools";
import LessonsList from "../admin/themes/courses";
import PasswordReset from "../auth/reset-password";
import RolePermissions from "../admin/role-permissions";
import EditRolePermission from "../admin/role-permissions/edit-role-permission";
import AddRolePermission from "../admin/role-permissions/add-role-permission";
import Users from "../admin/users";
import AddUser from "../admin/users/add-user";
import EditUser from "../admin/users/edit-user";
import Dashboard from "../admin/dashboard";
import Faqs from "../admin/faqs";
import AddFaq from "../admin/faqs/add-faq";
import EditFaq from "../admin/faqs/edit-faq";
import BoardDashboard from "../admin/board-dashboard";
import StatePage from "@ui/components/state-page";
import { PermissionType } from "@application/common/enums/permission-type";
import { Module } from "@application/common/enums/modules";
import { RoleEnum } from "@application/common/enums/role";
import ForgetPassword from "../auth/forget-password";
import AddSchoolTeacher from "../admin/schools/add-school-teacher";
import ChangeUserPassword from "../admin/users/change-user-password";
const ADMIN_ROLES = [
    RoleEnum[RoleEnum.SuperAdmin],
    RoleEnum[RoleEnum.Admin],
    RoleEnum[RoleEnum.Board],
    RoleEnum[RoleEnum.School],
];
const AppRoutes = () => {
    const { currentUser } = useAppSelector((state) => state.auth);
    return (_jsxs(Routes, { children: [_jsxs(Route, { element: _jsx(AuthLayout, {}), children: [_jsx(Route, { path: "login", element: _jsx(Login, {}) }), _jsx(Route, { path: "reset-password", element: _jsx(PasswordReset, {}) }), _jsx(Route, { path: "forget-password", element: _jsx(ForgetPassword, {}) })] }), _jsxs(Route, { element: _jsx(Layout, {}), children: [_jsxs(Route, { path: "admin", children: [_jsx(Route, { index: true, element: currentUser ? (_jsx(Navigate, { to: "dashboard" })) : (_jsx(Navigate, { to: "/login" })) }), currentUser?.role === RoleEnum[RoleEnum.SuperAdmin] ||
                                currentUser?.role === RoleEnum[RoleEnum.Admin] ? (_jsx(Route, { path: "dashboard", element: _jsx(ProtectedRoute, { module: Module[Module.Dashboard], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Dashboard, {}) }) })) : (_jsx(Route, { path: "dashboard", element: _jsx(ProtectedRoute, { module: Module[Module.Dashboard], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(BoardDashboard, {}) }) })), _jsx(Route, { path: "boards", element: _jsx(ProtectedRoute, { module: Module[Module.Boards], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Board, {}) }) }), _jsx(Route, { path: "boards/add", element: _jsx(ProtectedRoute, { module: Module[Module.Boards], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddBoards, {}) }) }), _jsx(Route, { path: "boards/:type", element: _jsx(ProtectedRoute, { module: Module[Module.Boards], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditBoards, {}) }) }), _jsx(Route, { path: "boards/:boardId/schools", element: _jsx(ProtectedRoute, { module: Module[Module.Boards], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(SchoolList, {}) }) }), _jsx(Route, { path: "schools", element: _jsx(ProtectedRoute, { module: Module[Module.Schools], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Schools, {}) }) }), _jsx(Route, { path: "schools/add", element: _jsx(ProtectedRoute, { module: Module[Module.Schools], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddSchool, {}) }) }), _jsx(Route, { path: "schools/:type", element: _jsx(ProtectedRoute, { module: Module[Module.Schools], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditSchool, {}) }) }), _jsx(Route, { path: "schools/:type/teachers", element: _jsx(ProtectedRoute, { module: Module[Module.Schools], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(TeachersList, {}) }) }), _jsx(Route, { path: "schools/:type/teachers/add", element: _jsx(ProtectedRoute, { module: Module[Module.Schools], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(AddSchoolTeacher, {}) }) }), _jsx(Route, { path: "teachers", element: _jsx(ProtectedRoute, { module: Module[Module["Teachers & Supervisors"]], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Teachers, {}) }) }), _jsx(Route, { path: "teachers/add", element: _jsx(ProtectedRoute, { module: Module[Module["Teachers & Supervisors"]], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddTeacher, {}) }) }), _jsx(Route, { path: "teachers/:type", element: _jsx(ProtectedRoute, { module: Module[Module["Teachers & Supervisors"]], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditTeacher, {}) }) }), _jsx(Route, { path: "goals", element: _jsx(ProtectedRoute, { module: Module[Module.Goals], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Goals, {}) }) }), _jsx(Route, { path: "goals/add", element: _jsx(ProtectedRoute, { module: Module[Module.Goals], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddGoal, {}) }) }), _jsx(Route, { path: "goals/:type", element: _jsx(ProtectedRoute, { module: Module[Module.Goals], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditGoal, {}) }) }), _jsx(Route, { path: "goals/:goalId/subgoals", element: _jsx(ProtectedRoute, { module: Module[Module.Goals], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(SubGoalsList, {}) }) }), _jsx(Route, { path: "sub-goals", element: _jsx(ProtectedRoute, { module: Module[Module["Sub-Goals"]], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(SubGoals, {}) }) }), _jsx(Route, { path: "sub-goals/add", element: _jsx(ProtectedRoute, { module: Module[Module["Sub-Goals"]], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddSubGoal, {}) }) }), _jsx(Route, { path: "sub-goals/:type", element: _jsx(ProtectedRoute, { module: Module[Module["Sub-Goals"]], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditSubGoal, {}) }) }), _jsx(Route, { path: "themes", element: _jsx(ProtectedRoute, { module: Module[Module.Themes], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Themes, {}) }) }), _jsx(Route, { path: "themes/add", element: _jsx(ProtectedRoute, { module: Module[Module.Themes], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddTheme, {}) }) }), _jsx(Route, { path: "themes/:type", element: _jsx(ProtectedRoute, { module: Module[Module.Themes], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditTheme, {}) }) }), _jsx(Route, { path: "themes/:themeId/lessons", element: _jsx(ProtectedRoute, { module: Module[Module.Themes], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(LessonsList, {}) }) }), _jsx(Route, { path: "lessons", element: _jsx(ProtectedRoute, { module: Module[Module.Lessons], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Lessons, {}) }) }), _jsx(Route, { path: "lessons/add", element: _jsx(ProtectedRoute, { module: Module[Module.Lessons], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddLesson, {}) }) }), _jsx(Route, { path: "lessons/:type", element: _jsx(ProtectedRoute, { module: Module[Module.Lessons], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditLesson, {}) }) }), _jsx(Route, { path: "vouchers", element: _jsx(ProtectedRoute, { module: Module[Module.Vouchers], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Vouchers, {}) }) }), _jsx(Route, { path: "vouchers/add", element: _jsx(ProtectedRoute, { module: Module[Module.Vouchers], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddVoucher, {}) }) }), _jsx(Route, { path: "news", element: _jsx(ProtectedRoute, { module: Module[Module.News], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(News, {}) }) }), _jsx(Route, { path: "news/add", element: _jsx(ProtectedRoute, { module: Module[Module.News], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddNews, {}) }) }), _jsx(Route, { path: "news/:type", element: _jsx(ProtectedRoute, { module: Module[Module.News], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditNews, {}) }) }), _jsx(Route, { path: "agreements", element: _jsx(ProtectedRoute, { module: Module[Module.Agreements], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Agreements, {}) }) }), _jsx(Route, { path: "agreements/add", element: _jsx(ProtectedRoute, { module: Module[Module.Agreements], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddAgreement, {}) }) }), _jsx(Route, { path: "agreements/:type", element: _jsx(ProtectedRoute, { module: Module[Module.Agreements], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditAgreement, {}) }) }), _jsx(Route, { path: "permissions", element: _jsx(ProtectedRoute, { module: Module[Module["Role Permissions"]], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(RolePermissions, {}) }) }), _jsx(Route, { path: "permissions/add", element: _jsx(ProtectedRoute, { module: Module[Module["Role Permissions"]], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddRolePermission, {}) }) }), _jsx(Route, { path: "permissions/:type", element: _jsx(ProtectedRoute, { module: Module[Module["Role Permissions"]], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditRolePermission, {}) }) }), _jsx(Route, { path: "users", element: _jsx(ProtectedRoute, { module: Module[Module.Users], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Users, {}) }) }), _jsx(Route, { path: "users/add", element: _jsx(ProtectedRoute, { module: Module[Module.Users], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddUser, {}) }) }), _jsx(Route, { path: "users/:type", element: _jsx(ProtectedRoute, { module: Module[Module.Users], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditUser, {}) }) }), _jsx(Route, { path: "users/changePassword/:id", element: _jsx(ProtectedRoute, { module: Module[Module.Users], permissionTypes: [
                                        PermissionType[PermissionType.editPermission],
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(ChangeUserPassword, {}) }) }), _jsx(Route, { path: "faqs", element: _jsx(ProtectedRoute, { module: Module[Module.FAQs], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                    ], children: _jsx(Faqs, {}) }) }), _jsx(Route, { path: "faqs/add", element: _jsx(ProtectedRoute, { module: Module[Module.FAQs], permissionTypes: [
                                        PermissionType[PermissionType.createPermission],
                                    ], children: _jsx(AddFaq, {}) }) }), _jsx(Route, { path: "faqs/:type", element: _jsx(ProtectedRoute, { module: Module[Module.FAQs], permissionTypes: [
                                        PermissionType[PermissionType.viewPermission],
                                        PermissionType[PermissionType.editPermission],
                                    ], children: _jsx(EditFaq, {}) }) })] }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: `/admin`, replace: true }) })] }), _jsx(Route, { path: "statepage", element: _jsx(StatePage, {}) })] }));
};
export default AppRoutes;
