import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { addRolePermissionsAsync, enableDisableRolePermissionsAsync, getRolePermissionByIdAsync, getRolePermissionByNameAsync, updateRolePermissionsAsync, } from "./rolePermissionThunk";
const initialState = {
    status: APIStatus.IDLE,
};
export const rolePermissionSlice = createSlice({
    name: "rolePermission",
    initialState,
    reducers: {
        setDefaultRolePermission(state, action) {
            state.loggedInUserRolePermission = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRolePermissionByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getRolePermissionByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.rolePermission = action.payload;
        })
            .addCase(getRolePermissionByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getRolePermissionByNameAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getRolePermissionByNameAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.loggedInUserRolePermission = action.payload;
        })
            .addCase(getRolePermissionByNameAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addRolePermissionsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addRolePermissionsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addRolePermissionsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateRolePermissionsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateRolePermissionsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateRolePermissionsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableRolePermissionsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableRolePermissionsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableRolePermissionsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    },
});
export const { setDefaultRolePermission } = rolePermissionSlice.actions;
export default rolePermissionSlice.reducer;
