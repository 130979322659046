import { configureStore, combineReducers, } from "@reduxjs/toolkit";
import loadingReducer from "@application/features/common/loadingSlice";
import notificationReducer from "@application/features/common/notificationSlice";
import authReducer from "@application/features/auth/authSlice";
import layoutReducer from "@application/features/layout/layoutSlice";
import toggleReducer from "@application/features/common/toggleSlice";
import boardReducer from "@application/features/board/boardSlice";
import countryReducer from "@application/features/country/countrySlice";
import goalReducer from "@application/features/goal/goalSlice";
import subGoalReducer from "@application/features/sub-goal/subGoalSlice";
import schoolReducer from "@application/features/school/schoolSlice";
import teacherReducer from "@application/features/teacher/teacherSlice";
import themeReducer from "@application/features/theme/themeSlice";
import seasonReducer from "@application/features/season/seasonSlice";
import courseReducer from "@application/features/course/courseSlice";
import voucherReducer from "@application/features/voucher/voucherSlice";
import roleReducer from "@application/features/role/roleSlice";
import newsReducer from "@application/features/news/newsSlice";
import portalReducer from "@application/features/portal/portalSlice";
import agreementReducer from "@application/features/agreements/agreementSlice";
import rolePermissionReducer from "@application/features/role-permission/rolePermissionSlice";
import userReducer from "@application/features/user/userSlice";
import faqReducer from "@application/features/faq/faqSlice";
import dashboardReducer from "@application/features/dashboard/dashboardSlice";
const combinedReducers = combineReducers({
    loading: loadingReducer,
    layout: layoutReducer,
    notification: notificationReducer,
    auth: authReducer,
    toggle: toggleReducer,
    board: boardReducer,
    country: countryReducer,
    goal: goalReducer,
    subGoal: subGoalReducer,
    school: schoolReducer,
    teacher: teacherReducer,
    theme: themeReducer,
    season: seasonReducer,
    course: courseReducer,
    voucher: voucherReducer,
    role: roleReducer,
    news: newsReducer,
    portal: portalReducer,
    agreement: agreementReducer,
    rolePermission: rolePermissionReducer,
    user: userReducer,
    faq: faqReducer,
    dashboard: dashboardReducer
});
const rootReducer = (state, action) => {
    if (action.type === "auth/logout") {
        return combinedReducers(undefined, action);
    }
    return combinedReducers(state, action);
};
export const store = configureStore({
    reducer: rootReducer,
});
