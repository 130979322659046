import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import "./style.scss";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import WNSwitch from "@ui/components/wn-switch";
import { DataGrid, } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect, useState } from "react";
import { AppConsts } from "@application/common/constants/app-consts";
import { formateDate } from "@application/common/helpers/date-time-helper";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { enableDisableNewsAsync, getDownloadAndViewNewsAsync, getNewsAsync, } from "@application/features/news/newsThunk";
import moment from "moment";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
import { PortalTypeStatusMap } from "@application/common/enums/portal-type";
const News = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [imageFile, setImageFile] = useState(undefined);
    const { news } = useAppSelector((state) => state.news);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.News]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getNewsAsync());
    }, [dispatch]);
    const viewNews = (file) => {
        const fileName = file.split("/")[1];
        dispatch(getDownloadAndViewNewsAsync(fileName)).then((res) => {
            if (res.payload !== null &&
                res.payload !== undefined &&
                res.payload !== "") {
                const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                setImageFile(base64Data);
                handleClickOpen();
            }
        });
    };
    const downloadNews = (file) => {
        if (file) {
            const fileName = file.split("/")[1];
            if (fileName) {
                dispatch(getDownloadAndViewNewsAsync(fileName)).then((res) => {
                    if (res.payload !== null &&
                        res.payload !== undefined &&
                        res.payload !== "") {
                        const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                        var a = document.createElement("a");
                        a.href = base64Data;
                        a.download = `FeatureImage.${fileName.split(".")[1]}`;
                        a.target = "_blank";
                        a.click();
                    }
                });
            }
            else {
                console.log("no file name");
            }
        }
        else {
            console.log("no file");
        }
    };
    const onDetailsClick = (news) => {
        navigate(`${news.id}`);
    };
    const columns = [
        {
            field: "title",
            headerName: translate("news.news").toUpperCase(),
            sortable: true,
            flex: 1,
            hideable: false,
        },
        {
            field: "featureImage",
            headerName: translate("common.featureImage").toUpperCase(),
            sortable: false,
            flex: 1,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsxs(_Fragment, { children: [_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => viewNews(params.row.picture), children: translate("cta.view") }), _jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => downloadNews(params.row.picture), children: translate("cta.download") })] })),
        },
        {
            field: "targetPortals",
            headerName: translate("news.targetPortals").toUpperCase(),
            sortable: true,
            flex: 1,
            valueGetter: (params) => params.row.portals.length
                ? `${params.row.portals.map((item) => {
                    return `${PortalTypeStatusMap.get(item.portalType)}`;
                })}`
                : "",
        },
        // {
        //   field: "postDate",
        //   headerName: translate("news.postDate").toUpperCase(),
        //   sortable: true,
        //   flex: 1,
        //   align: "center",
        //   headerAlign: "center",
        //   valueGetter: (params: GridValueGetterParams) =>
        //     `${formateDate(params.row.publishDate, DateFormatConstants.MDY)}`,
        // },
        {
            field: "publishedDate",
            headerName: translate("news.publishDate").toUpperCase(),
            sortable: true,
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${formateDate(params.row.publishDate, DateFormatConstants.MDY)}`,
        },
        {
            field: "status",
            headerName: translate("news.status").toUpperCase(),
            sortable: true,
            flex: 1,
            valueGetter: (params) => `${moment(params.row.publishDate).toDate() <= new Date()
                ? translate("news.published")
                : translate("news.published")}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            // flex: 1,
            filterable: false,
            hideable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNSwitch, { style: {
                    alignItems: "center",
                    marginLeft: "unset",
                    marginRight: "unset",
                }, checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.id) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            // flex: 1,
            filterable: false,
            hideable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => onDetailsClick(params.row), children: translate("cta.details") })),
        },
    ];
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableNewsAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getNewsAsync());
            }
        });
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("news.news") }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/news/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: news ?? [], columns: columns, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !news.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            featureImage: hasViewPermission ?? true,
                            isActive: hasEditPermission ?? true,
                            "": (hasViewPermission || hasEditPermission) ?? true,
                        } }, "theme-grid") }) }), _jsxs(BootstrapDialog, { onClose: handleClose, "aria-labelledby": "customized-dialog-title", open: open, children: [_jsx(IconButton, { "aria-label": "close", onClick: handleClose, sx: {
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }, children: _jsx(CloseIcon, {}) }), _jsx(DialogContent, { children: imageFile && _jsx("img", { src: imageFile, width: "100%", alt: "no-img-file" }) })] })] }));
};
export default News;
