import { translate } from "../helpers/translate";
export var SignedUpType;
(function (SignedUpType) {
    SignedUpType[SignedUpType["Wijzneus"] = 0] = "Wijzneus";
    SignedUpType[SignedUpType["Heutink"] = 1] = "Heutink";
})(SignedUpType || (SignedUpType = {}));
export const SignedUpTypeStatusMap = new Map([
    [SignedUpType.Wijzneus, translate("enums.signedUpType.wijzneus")],
    [SignedUpType.Heutink, translate("enums.signedUpType.heutink")],
]);
