import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect } from "react";
import WNSwitch from "@ui/components/wn-switch";
import { DataGrid } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { enableDisableRoleAsync, getRolesAsync, } from "@application/features/role/roleThunk";
import { RoleEnum } from "@application/common/enums/role";
import "./style.scss";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
const RolePermissions = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { roles } = useAppSelector((state) => state.role);
    const { currentUser } = useAppSelector((state) => state.auth);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module["Role Permissions"]]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getRolesAsync());
    }, [dispatch]);
    const onEnableDisable = ($event, id, name) => {
        // if (includeRoles.includes(name)) {
        //   return;
        // }
        dispatch(enableDisableRoleAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getRolesAsync());
            }
        });
    };
    const onDetailsClick = (role) => {
        navigate(`${role.id}`);
    };
    const includeRoles = [
        "SuperAdmin",
        "Admin",
        "Board",
        "School",
        "Teacher",
        "Supervisor",
        "Student",
    ];
    const columns = [
        {
            field: "roleDisplayName",
            headerName: translate("permissions.roleName").toUpperCase(),
            sortable: true,
            flex: 1,
            hideable: false,
        },
        {
            field: "usersCount",
            headerName: translate("permissions.users").toUpperCase(),
            sortable: true,
            flex: 1,
            hideable: false,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, 
                //disable={includeRoles.includes(params.row.name)}
                disable: params.row.name == RoleEnum[RoleEnum.SuperAdmin], onChange: ($event) => onEnableDisable($event, params.row.id, params.row.name) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("permissions.permissions").toUpperCase(),
            sortable: false,
            flex: 1,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            renderCell: (params) => params.row.name == RoleEnum[RoleEnum.SuperAdmin] ? (_jsx(_Fragment, { children: " " })) : (_jsx(Button, { size: "small", className: "Button", disabled: !params.row.isActive, sx: { fontSize: "14px" }, onClick: () => onDetailsClick(params.row), children: translate("cta.details") })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("permissions.permissions") }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: roles?.filter((role) => {
                            // Exclude SuperAdmin role for non-super admin users
                            if (currentUser?.role != RoleEnum[RoleEnum.SuperAdmin] &&
                                role.name == RoleEnum[RoleEnum.SuperAdmin])
                                return false;
                            // Exclude Student role
                            if (role.name == RoleEnum[RoleEnum.Student])
                                return false;
                            return true;
                        }) ?? [], columns: columns, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !roles?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                            "": (hasViewPermission || hasEditPermission) ?? true,
                        } }, "role-grid") }) })] }));
};
export default RolePermissions;
