import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import "./App.scss";
import i18n from "./i18n";
import { ToastContainer } from "react-toastify";
import Loading from "./ui/components/wn-loader";
import { resetStateByPathName } from "./application/common/helpers/state-helper";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./application/store/useStore";
import AppRoutes from "./ui/containers/layout/AppRoutes";
import { logout } from "./application/features/auth/authSlice";
import "react-toastify/dist/ReactToastify.css";
import { getRolePermissionByNameAsync } from "@application/features/role-permission/rolePermissionThunk";
function App() {
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector((state) => state.loading);
    const { currentUser } = useAppSelector((state) => state.auth);
    const location = useLocation();
    useEffect(() => {
        moment.locale(i18n.language);
    }, []);
    useEffect(() => {
        const onStorageChange = (event) => {
            if (event.key === "AUTH_TOKEN" && event.newValue === null)
                dispatch(logout());
        };
        window.addEventListener("storage", onStorageChange);
        return () => {
            window.removeEventListener("storage", onStorageChange);
        };
    }, [dispatch]);
    useEffect(() => {
        return () => {
            resetStateByPathName(location.pathname);
        };
    }, [location.pathname]);
    useEffect(() => {
        if (currentUser)
            dispatch(getRolePermissionByNameAsync(currentUser.role));
    }, [currentUser]);
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, { position: "top-right", autoClose: 1000, newestOnTop: true, pauseOnHover: true, theme: "colored" }), isLoading && _jsx(Loading, { isLoading: isLoading }), _jsx("div", { className: "boxed_wrapper", children: _jsx(AppRoutes, {}) })] }));
}
export default App;
