import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./style.scss";
import logo from "../../../../logo.svg";
import loginImage from "../../../../login-feature-nose.png";
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { translate } from "@application/common/helpers/translate";
import WnValidation from "@ui/components/wn-validation";
import { forcedPasswordChangedAsync } from "@application/features/auth/authThunk";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { logout } from "@application/features/auth/authSlice";
function PasswordReset() {
    const { apiError } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const { control, formState: { errors }, handleSubmit, setValue, watch, } = useForm({
        defaultValues: {
            email: "",
            password: "",
            confirmPassword: "",
            token: "",
        },
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    useEffect(() => {
        if (email && token) {
            setValue("email", decodeURIComponent(email));
            setValue("token", token.replace(/\s/g, "+"));
        }
    }, [email, setValue, token]);
    const onSubmit = (credentials) => {
        dispatch(logout());
        dispatch(forcedPasswordChangedAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate(`/login`);
            }
        });
    };
    const validatePassword = (password) => {
        if (password.length < 8)
            return translate("validations.mustHaveEightCharacters");
        if (!/[A-Z]+/.test(password))
            return translate("validations.mustHaveUpperCaseLetter");
        if (!/[a-z]+/.test(password))
            return translate("validations.mustHaveLowerCaseLetter");
        if (!/[0-9]+/.test(password))
            return translate("validations.mustHaveOneNumber");
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return translate("validations.mustHaveSpecialCharacter");
    };
    const validateConfirmPassword = (confirmPassword) => {
        if (watch("password") !== confirmPassword)
            return translate("validations.passwordDonotMatch");
    };
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: logo, className: "login-logo", height: 90, alt: "logo" }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "fields", children: _jsxs(Container, { sx: { width: "80%" }, children: [_jsx(Typography, { variant: "h4", sx: {
                                        fontWeight: "700",
                                        color: "winter.main",
                                        marginBottom: "40px",
                                    }, children: translate("account.passwordReset") }), _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "login-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 4, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(FormControl, { variant: "outlined", size: "small", sx: { width: "100%" }, children: [_jsx(InputLabel, { shrink: true, htmlFor: "outlined-adornment-password", children: translate("account.password") }), _jsx(Controller, { name: "password", control: control, rules: {
                                                                required: {
                                                                    value: true,
                                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("account.password")),
                                                                },
                                                                validate: (value) => validatePassword(value),
                                                            }, render: ({ field: { onChange, value } }) => (_jsx(OutlinedInput, { notched: true, id: "outlined-adornment-password", type: showPassword ? "text" : "password", name: "password", placeholder: translate("account.password"), onChange: onChange, value: value, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end", children: showPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }), label: translate("account.password") })) }), _jsx(WnValidation, { fieldError: errors.password })] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(FormControl, { variant: "outlined", size: "small", sx: { width: "100%" }, children: [_jsx(InputLabel, { shrink: true, htmlFor: "outlined-adornment-password", children: translate("account.confirmPassword") }), _jsx(Controller, { name: "confirmPassword", control: control, rules: {
                                                                required: {
                                                                    value: true,
                                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("account.confirmPassword")),
                                                                },
                                                                validate: (value) => validateConfirmPassword(value),
                                                            }, render: ({ field: { onChange, value } }) => (_jsx(OutlinedInput, { notched: true, id: "outlined-adornment-password", type: showConfirmPassword ? "text" : "password", name: "confirmPassword", placeholder: translate("account.confirmPassword"), onChange: onChange, value: value, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowConfirmPassword, onMouseDown: handleMouseDownPassword, edge: "end", children: showConfirmPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }), label: translate("account.confirmPassword") })) }), _jsx(WnValidation, { fieldError: errors.confirmPassword })] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Button, { className: "Button", variant: "contained", color: "customDark", disableElevation: true, size: "large", sx: { width: "100%" }, type: "submit", children: translate("cta.submit") }) })] }) })] }) }), _jsx("div", { className: "feature", children: _jsx("img", { src: loginImage, style: { maxWidth: 400 }, height: "85%", alt: "feature" }) })] })] }));
}
export default PasswordReset;
