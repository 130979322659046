export const modules = [
    "Dashboard",
    "Boards",
    "Schools",
    "Teachers & Supervisors",
    "Goals",
    "Sub-Goals",
    "Themes",
    "Lessons",
    "Vouchers",
    "News",
    "FAQs",
    "Agreements",
    "Invoices",
    "Role Permissions",
    "Users",
];
