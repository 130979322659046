import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from "@application/common/enums/api-status";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { PortalTypeStatusMap } from "@application/common/enums/portal-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { getFaqByPortalAsync, updateFaqAsync, } from "@application/features/faq/faqThunk";
import { getPortalDropdownAsync } from "@application/features/portal/portalThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Box, Button, Divider, Grid, IconButton, TextField, Typography, useTheme, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
const EditFaq = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { type } = useParams();
    const { faq, status } = useAppSelector((state) => state.faq);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.FAQs]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const { register, setValue, control, formState: { errors }, handleSubmit, reset, } = useForm({
        defaultValues: {},
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "faqItems",
    });
    useEffect(() => {
        dispatch(getPortalDropdownAsync());
        reset({
            faqItems: [],
        });
        setValue("faqItems", []);
    }, []);
    useEffect(() => {
        if (type)
            dispatch(getFaqByPortalAsync(type));
    }, [type]);
    useEffect(() => {
        if (faq && faq.portal && type && status !== APIStatus.PENDING) {
            setValue("portalId", faq?.portal?.id);
            setValue("faqItems", faq.faqItems);
            if (faq && faq.faqItems && faq.faqItems.length > 0) {
                faq.faqItems.forEach((value, index) => {
                    setValue(`faqItems.${index}.id`, value.id);
                    setValue(`faqItems.${index}.question`, value.question);
                    setValue(`faqItems.${index}.answer`, value.answer);
                });
            }
        }
    }, [faq, status, type, setValue, dispatch]);
    const onAddNewFaq = () => {
        append({
            id: null,
            question: "",
            answer: "",
        });
    };
    const onSubmit = (credentials) => {
        dispatch(updateFaqAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/faqs");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.faqsDetails") }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "news-form", noValidate: true, children: status == APIStatus.IDLE && (_jsxs(_Fragment, { children: [_jsx(Grid, { container: true, spacing: 2.5, sx: { mb: 3 }, children: _jsx(Grid, { item: true, lg: 4, xs: 12, children: faq?.portal && (_jsx(TextField, { id: "portal", label: translate("faqs.portal"), variant: "outlined", size: "small", type: "text", fullWidth: true, value: PortalTypeStatusMap.get(faq?.portal.portalType), disabled: true, InputLabelProps: { shrink: true } })) }) }), _jsx(Divider, {}), _jsx(Typography, { variant: "h5", sx: { mt: 1, mb: 3 }, children: translate("faqs.faqs") }), fields.map((item, index) => (_jsx("div", { children: _jsx(Grid, { container: true, spacing: 3.5, marginBottom: 3, children: _jsx(Grid, { item: true, xs: 12, children: _jsxs(Box, { sx: {
                                                p: 2,
                                                borderRadius: "12px",
                                                border: "1px solid #87CEF3",
                                                position: "relative",
                                            }, children: [fields.length > 1 && hasEditPermission && (_jsx(IconButton, { size: "small", color: "error", sx: {
                                                        backgroundColor: theme.palette.white.main,
                                                        position: "absolute",
                                                        top: "0",
                                                        right: "0",
                                                        transform: "translate(50%, -50%)",
                                                    }, onClick: () => remove(index), children: _jsx("span", { className: "material-symbols-rounded", children: "delete" }) })), _jsxs(Grid, { container: true, rowSpacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { ...register(`faqItems.${index}.question`, {
                                                                    required: {
                                                                        value: true,
                                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("faqs.question")),
                                                                    },
                                                                    // maxLength: {
                                                                    //   value: 100,
                                                                    //   message: "Maximum 100 characters are allowed.",
                                                                    // },
                                                                }), id: "outlined-basic", label: `${index + 1}. ${translate("faqs.question")}`, placeholder: translate("faqs.question"), variant: "outlined", size: "small", fullWidth: true, disabled: !hasEditPermission, error: errors.faqItems &&
                                                                    !!errors.faqItems[index]?.question, helperText: errors.faqItems &&
                                                                    errors.faqItems[index]?.question?.message }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { ...register(`faqItems.${index}.answer`, {
                                                                    required: {
                                                                        value: true,
                                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("faqs.answer")),
                                                                    },
                                                                    // maxLength: {
                                                                    //   value: 100,
                                                                    //   message: "Maximum 100 characters are allowed.",
                                                                    // },
                                                                }), id: "outlined-basic", label: translate("faqs.answer"), placeholder: translate("faqs.answer"), variant: "outlined", size: "small", multiline: true, fullWidth: true, disabled: !hasEditPermission, rows: 3, error: errors.faqItems &&
                                                                    !!errors.faqItems[index]?.answer, helperText: errors.faqItems &&
                                                                    errors.faqItems[index]?.answer?.message }) })] })] }) }) }) }, `main-div-${item.id}`))), hasEditPermission && (_jsx(Grid, { item: true, xs: 12, sx: { textAlign: "right" }, children: _jsx(Button, { variant: "text", color: "primary", className: "Button", onClick: () => onAddNewFaq(), children: translate("cta.addNewFaq") }) })), hasEditPermission && (_jsx("div", { style: { textAlign: "right", marginTop: "24px" }, children: _jsx(Button, { variant: "contained", color: "primary", className: "Button", type: "submit", children: translate("cta.saveChanges") }) }))] })) }) })] }));
};
export default EditFaq;
