import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import WNSwitch from "@ui/components/wn-switch";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect } from "react";
import { enableDisableTeacherAsync } from "@application/features/teacher/teacherThunk";
import { getTeachersBySchoolIdAsync } from "@application/features/school/schoolThunk";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import GoBack from "@ui/components/wn-go-back";
import "./style.scss";
import { PermissionType } from "@application/common/enums/permission-type";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
const TeachersList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { type } = useParams();
    const { schoolTeachers } = useAppSelector((state) => state.school);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module["Teachers & Supervisors"]]);
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        if (type) {
            dispatch(getTeachersBySchoolIdAsync(type));
        }
    }, [dispatch, type]);
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableTeacherAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                if (type)
                    dispatch(getTeachersBySchoolIdAsync(type));
            }
        });
    };
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "name",
            headerName: translate("account.name").toUpperCase(),
            sortable: true,
            minWidth: isSmall ? 180 : undefined,
        },
        {
            field: "school",
            headerName: translate("schools.school").toUpperCase(),
            sortable: true,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.school.name}`,
        },
        {
            field: "email",
            headerName: translate("account.email").toUpperCase(),
            sortable: true,
            minWidth: isSmall ? 180 : undefined,
        },
        {
            field: "role",
            headerName: translate("account.role").toUpperCase(),
            sortable: true,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => {
                const roleName = params.row.role.name;
                const translatedRoleName = translate(`teachers&Supervisors.${roleName.toLowerCase()}`);
                return translatedRoleName;
            },
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            minWidth: isSmall ? 180 : undefined,
            cellClassName: "tableSwitchAlignCenter",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, disable: !params.row.school.isActive, onChange: ($event) => onEnableDisable($event, params.row.id) }, params.row.id)),
        },
        {
            field: "",
            minWidth: isSmall ? 130 : undefined,
            headerName: translate("common.actions").toUpperCase(),
            renderCell: (params) => (_jsx(Button, { size: "small", 
                // disabled={!params.row.isActive}
                className: "Button", sx: { fontSize: "14px" }, onClick: () => navigate(`/admin/teachers/${params.row.id}`), children: translate("cta.details") })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("teachers&Supervisors.teachers&Supervisors") }) }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate(`/admin/schools/${type}/teachers/add`), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx(Box, { sx: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: schoolTeachers ?? [], columns: columns, hideFooterPagination: !schoolTeachers?.length, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                        } }, "teacher-grid") }) })] }));
};
export default TeachersList;
