import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { getUserByIdAsync, updateUserPasswordAsync, } from "@application/features/user/userThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import React from "react";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import AlertDialog from "@ui/components/wn-dialog";
import { setOpenDialog } from "@application/features/user/userSlice";
const ChangeUserPassword = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, status, isOpenDialog } = useAppSelector((state) => state.user);
    const { roleDropdownList } = useAppSelector((state) => state.role);
    const [loading, setLoading] = useState(false);
    const { boardDropdownList, schoolDropdownList, status: dropdownStatus, } = useAppSelector((state) => state.board);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Users]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const [selectedRole, setSelectedRole] = useState(user?.role?.label);
    const [changeUserPassword, setChangeUserPassword] = useState();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    const validatePassword = (password) => {
        if (password.length < 8)
            return translate("validations.mustHaveEightCharacters");
        if (!/[A-Z]+/.test(password))
            return translate("validations.mustHaveUpperCaseLetter");
        if (!/[a-z]+/.test(password))
            return translate("validations.mustHaveLowerCaseLetter");
        if (!/[0-9]+/.test(password))
            return translate("validations.mustHaveOneNumber");
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return translate("validations.mustHaveSpecialCharacter");
    };
    const validateConfirmPassword = (confirmPassword) => {
        if (watch("newPassword") !== confirmPassword)
            return translate("validations.passwordDonotMatch");
    };
    const handleOpenDialog = (params) => {
        setChangeUserPassword(params);
        dispatch(setOpenDialog(true));
    };
    const handleCloseDialog = () => {
        dispatch(setOpenDialog(false));
    };
    const handleConfirmAction = () => {
        if (updateUserPasswordAsync) {
            dispatch(updateUserPasswordAsync(changeUserPassword)).then((res) => {
                if (res.payload === true) {
                    navigate("/admin/users");
                }
            });
        }
        handleCloseDialog();
    };
    const { watch, register, formState: { errors }, control, setError, setValue, handleSubmit, } = useForm();
    useEffect(() => { }, []);
    useEffect(() => {
        debugger;
        if (id) {
            dispatch(getUserByIdAsync(id)).then((res) => {
                setLoading(true);
            });
        }
    }, [dispatch, id]);
    useEffect(() => {
        debugger;
        if (user && user.role && loading) {
            setValue("id", user.id);
        }
    }, [user, loading]);
    const onSubmit = (credentials) => {
        credentials.id = id ?? "";
        handleOpenDialog(credentials);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("users.changePassword") }) }) }), _jsxs(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: [_jsx("form", { onSubmit: handleSubmit(onSubmit), id: "user-form", noValidate: true, children: _jsxs(_Fragment, { children: [_jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, sm: 6, xs: 12, children: _jsxs(FormControl, { variant: "outlined", size: "small", sx: { width: "100%" }, children: [_jsx(InputLabel, { shrink: true, htmlFor: "outlined-adornment-password", children: translate("account.password") }), _jsx(Controller, { name: "newPassword", control: control, rules: {
                                                                required: {
                                                                    value: true,
                                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("account.password")),
                                                                },
                                                                validate: (value) => validatePassword(value),
                                                            }, render: ({ field: { onChange, value } }) => (
                                                            // eslint-disable-next-line react/jsx-no-undef
                                                            _jsx(OutlinedInput, { notched: true, id: "outlined-adornment-password", type: showPassword ? "text" : "password", name: "newPassword", placeholder: translate("account.password"), onChange: onChange, value: value, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end", children: showPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }), label: translate("account.password") })) }), _jsx(WnValidation, { fieldError: errors.newPassword })] }) }), _jsx(Grid, { item: true, sm: 6, xs: 12, children: _jsxs(FormControl, { variant: "outlined", size: "small", sx: { width: "100%" }, children: [_jsx(InputLabel, { shrink: true, htmlFor: "outlined-adornment-password", children: translate("account.confirmPassword") }), _jsx(Controller, { name: "confirmPassword", control: control, rules: {
                                                                required: {
                                                                    value: true,
                                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("account.confirmPassword")),
                                                                },
                                                                validate: (value) => validateConfirmPassword(value),
                                                            }, render: ({ field: { onChange, value } }) => (
                                                            // eslint-disable-next-line react/jsx-no-undef
                                                            _jsx(OutlinedInput, { notched: true, id: "outlined-adornment-password", type: showConfirmPassword ? "text" : "password", name: "newPassword", placeholder: translate("account.confirmPassword"), onChange: onChange, value: value, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowConfirmPassword, onMouseDown: handleMouseDownConfirmPassword, edge: "end", children: showConfirmPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }), label: translate("account.confirmPassword") })) }), _jsx(WnValidation, { fieldError: errors.confirmPassword })] }) })] }) }), hasEditPermission &&
                                    user &&
                                    user.email != "super-admin@wijzneus.com" && (_jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.saveChanges") }) }) }) }))] }) }), _jsx(AlertDialog, { open: isOpenDialog, onClose: handleCloseDialog, title: translate("updatePasswordDialog.title"), content: translate("updatePasswordDialog.content"), onConfirm: handleConfirmAction, onCancel: handleCloseDialog, confirmButtonText: translate("cta.proceed"), cancelButtonText: translate("cta.cancel") })] })] }));
};
export default ChangeUserPassword;
