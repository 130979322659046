import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import "./style.scss";
import React from "react";
import { useAppSelector } from "@application/store/useStore";
import DashboardActiveSchools from "./sections/active-schools";
import BoardDashboardNews from "./sections/news-section";
import BoardDashboardFaqs from "./sections/faqs-section";
import { RoleEnum } from "@application/common/enums/role";
import { translate } from "@application/common/helpers/translate";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (_jsx("div", { role: "tabpanel", hidden: value !== index, id: `simple-tabpanel-${index}`, "aria-labelledby": `simple-tab-${index}`, ...other, children: value === index && (_jsx(Box, { sx: { py: 2 }, children: _jsx(Typography, { children: children }) })) }));
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
};
function BoardDashboard() {
    const theme = useTheme();
    const { dashboard } = useAppSelector((state) => state.dashboard);
    const { currentUser } = useAppSelector((state) => state.auth);
    const defaultBoxStyle = {
        px: 2.75,
        py: 2,
        boxShadow: "0px 7px 47px 0px rgba(45, 138, 197, 0.10)",
        borderRadius: "12px",
        width: "100%",
        height: "100%",
        position: "relative",
        backgroundColor: theme.palette.common.white,
    };
    const activeSchools = {
        background: "linear-gradient(127deg, #12C2E9 0%, #C471ED 51.87%, #F64F59 100%)",
        color: theme.palette.common.white,
    };
    const otherInfoBox = {
        display: "flex",
        alignItems: "center",
        gap: 0.5,
    };
    const boxShadowColor = `rgba(${hexToRgb(theme.palette.primary.main)}, 0.1)`;
    const tabBoxStyle = {
        boxShadow: `0px 7px 47px 0px ${boxShadowColor}`,
        p: 2,
        pb: 4,
        backgroundColor: theme.palette.common.white,
        borderRadius: 1,
    };
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex", mb: 2.5 }, justifyContent: "space-between", alignItems: "center", children: _jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: "Dashboard" }) }), _jsxs(Grid, { container: true, spacing: 2.5, children: [currentUser?.role !== RoleEnum[RoleEnum.SuperAdmin] ||
                        (currentUser?.role !== RoleEnum[RoleEnum.Admin] && (_jsx(Grid, { item: true, lg: 3, md: 4, xs: 12, children: _jsx(Box, { sx: { ...activeSchools, ...defaultBoxStyle }, children: _jsx(DashboardActiveSchools, { activeSchoolsCount: dashboard?.activeSchoolCount ?? 0, totalSchoolsCount: dashboard?.totalSchoolCount ?? 0 }) }) }))), currentUser?.role !== RoleEnum[RoleEnum.SuperAdmin] ||
                        (currentUser?.role !== RoleEnum[RoleEnum.Admin] && (_jsx(Grid, { item: true, lg: 9, md: 8, xs: 12, children: _jsxs(Grid, { item: true, container: true, spacing: 2, children: [_jsx(Grid, { item: true, md: 6, xs: 12, children: _jsxs(Box, { sx: { ...otherInfoBox, ...defaultBoxStyle }, children: [_jsxs(Box, { sx: { flex: "1 1 auto" }, children: [_jsx(Box, { component: "img", sx: { mb: 2 }, src: "/images/dashboard-teacher.png" }), _jsx(Typography, { variant: "body1", sx: { fontWeight: "500" }, children: "Teachers" })] }), _jsx(Typography, { sx: { fontWeight: "bold", fontSize: 32 }, children: dashboard?.activeTeachersCount })] }) }), _jsx(Grid, { item: true, md: 6, xs: 12, children: _jsxs(Box, { sx: { ...otherInfoBox, ...defaultBoxStyle }, children: [_jsxs(Box, { sx: { flex: "1 1 auto" }, children: [_jsx(Box, { component: "img", sx: { mb: 2 }, src: "/images/dashboard-teacher.png" }), _jsx(Typography, { variant: "body1", sx: { fontWeight: "500" }, children: "Supervisors" })] }), _jsx(Typography, { sx: { fontWeight: "bold", fontSize: 32 }, children: dashboard?.activeTeachersCount })] }) }), _jsx(Grid, { item: true, md: 6, xs: 12, children: _jsxs(Box, { sx: { ...otherInfoBox, ...defaultBoxStyle }, children: [_jsxs(Box, { sx: { flex: "1 1 auto" }, children: [_jsx(Box, { component: "span", className: "material-symbols-rounded", sx: {
                                                                mb: 2,
                                                                color: theme.palette.common.black,
                                                                opacity: "25%",
                                                            }, children: "radio_button_checked" }), _jsx(Typography, { variant: "body1", sx: { fontWeight: "500" }, children: "Total Lessons" })] }), _jsx(Typography, { sx: { fontSize: 32, fontWeight: "bold" }, children: "24" })] }) }), _jsx(Grid, { item: true, md: 6, xs: 12, children: _jsxs(Box, { sx: { ...otherInfoBox, ...defaultBoxStyle }, children: [_jsxs(Box, { sx: { flex: "1 1 auto" }, children: [_jsx(Box, { component: "span", className: "material-symbols-rounded", sx: {
                                                                mb: 2,
                                                                color: theme.palette.common.black,
                                                                opacity: "25%",
                                                            }, children: "radio_button_checked" }), _jsxs(Typography, { variant: "body1", sx: { fontWeight: "500" }, children: [_jsxs("span", { children: ["Active School", _jsx("br", {})] }), _jsxs("span", { children: ["Heutlink", _jsx("br", {})] })] })] }), _jsx(Typography, { sx: { fontSize: 32, fontWeight: "bold" }, children: "24" })] }) })] }) }))), _jsx(Grid, { item: true, xs: 12, children: _jsxs(Box, { sx: { width: "100%" }, children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: "divider" }, children: _jsxs(Tabs, { value: value, onChange: handleChange, "aria-label": "basic tabs example", children: [_jsx(Tab, { label: translate("news.news"), ...a11yProps(0) }), _jsx(Tab, { label: translate("faqs.faqs"), ...a11yProps(1) })] }) }), _jsx(CustomTabPanel, { value: value, index: 0, children: _jsx(Box, { sx: tabBoxStyle, children: _jsx(BoardDashboardNews, {}) }) }), _jsx(CustomTabPanel, { value: value, index: 1, children: _jsx(BoardDashboardFaqs, {}) })] }) })] })] }));
}
export default BoardDashboard;
