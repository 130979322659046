import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorPayload } from "../../common/helpers/api-helper";
import DashboardApi from "@infrastructure/dashboard-api";
export const getDashboardDataAsync = createAsyncThunk("dashboard/fetchDashboardData", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await DashboardApi.getDashboardData();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
