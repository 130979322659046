import { modules } from "../constants/modules";
import { Module } from "../enums/modules";
export const newRolePermissions = () => {
    return modules.map((module) => ({
        module: module,
        viewPermission: module === Module[Module.Dashboard],
        createPermission: false,
        editPermission: false,
        deletePermission: false,
    }));
};
export const validateModulePermission = (permissions, moduleName) => {
    const modulePermission = permissions.find((permission) => permission.module === moduleName);
    return modulePermission;
};
