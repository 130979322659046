import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogContent, IconButton, useMediaQuery, } from "@mui/material";
import "./style.scss";
import { Add } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect, useState } from "react";
import WNSwitch from "@ui/components/wn-switch";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { enableDisableCourseAsync, getDownloadAndViewCourseAsync, } from "@application/features/course/courseThunk";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { getCoursesByThemeIdAsync } from "@application/features/theme/themeThunk";
import GoBack from "@ui/components/wn-go-back";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
const LessonsList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [imageFile, setImageFile] = useState(undefined);
    const { themeId } = useParams();
    const { courseList } = useAppSelector((state) => state.theme);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Lessons]);
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        if (themeId) {
            dispatch(getCoursesByThemeIdAsync(themeId));
        }
    }, [dispatch, themeId]);
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableCourseAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                if (themeId) {
                    dispatch(getCoursesByThemeIdAsync(themeId));
                }
            }
        });
    };
    const onDetailsClick = (courseGridDto) => {
        navigate(`/admin/lessons/${courseGridDto.id}`);
    };
    const viewCourse = (file) => {
        const fileName = file.split("/")[1];
        dispatch(getDownloadAndViewCourseAsync(fileName)).then((res) => {
            if (res.payload !== null &&
                res.payload !== undefined &&
                res.payload !== "") {
                const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                setImageFile(base64Data);
                handleClickOpen();
            }
        });
    };
    const downloadCourse = (file) => {
        if (file) {
            const fileName = file.split("/")[1];
            if (fileName) {
                dispatch(getDownloadAndViewCourseAsync(fileName)).then((res) => {
                    if (res.payload !== null &&
                        res.payload !== undefined &&
                        res.payload !== "") {
                        const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                        var a = document.createElement("a");
                        a.href = base64Data;
                        a.download = `FeatureImage.${fileName.split(".")[1]}`;
                        a.target = "_blank";
                        a.click();
                    }
                });
            }
            else {
                console.log("no file name");
            }
        }
        else {
            console.log("no file");
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "name",
            headerName: translate("lessons.lessonName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "featureImage",
            headerName: translate("common.featureImage").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsxs(_Fragment, { children: [_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => viewCourse(params.row.featuredImage), children: translate("cta.view") }), _jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => downloadCourse(params.row.featuredImage), children: translate("cta.download") })] })),
        },
        {
            field: "theme",
            headerName: translate("themes.theme").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.theme.name}`,
        },
        {
            field: "season",
            headerName: translate("lessons.season").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.theme.season.name}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            filterable: false,
            hideable: false,
            align: "center",
            headerAlign: "center",
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.id) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            filterable: false,
            hideable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => onDetailsClick(params.row), children: translate("cta.details") })),
        },
    ];
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: "Lessons" }) }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/lessons/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: courseList ?? [], columns: columns, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !courseList?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                        } }, "course-grid") }) }), _jsxs(BootstrapDialog, { onClose: handleClose, "aria-labelledby": "customized-dialog-title", open: open, children: [_jsx(IconButton, { "aria-label": "close", onClick: handleClose, sx: {
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }, children: _jsx(CloseIcon, {}) }), _jsx(DialogContent, { children: imageFile && _jsx("img", { src: imageFile, width: "100%", alt: "no-img-file" }) })] })] }));
};
export default LessonsList;
