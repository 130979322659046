import { jsx as _jsx } from "react/jsx-runtime";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { useAppSelector } from "@application/store/useStore";
import Loading from "@ui/components/wn-loader";
import { Navigate, useLocation } from "react-router-dom";
const ProtectedRoute = ({ children, module, permissionTypes, }) => {
    const location = useLocation();
    const { currentUser } = useAppSelector((state) => state.auth);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    if (!currentUser)
        return _jsx(Navigate, { to: "/login", state: { from: location } });
    if (loggedInUserRolePermission && loggedInUserRolePermission.length > 0) {
        const modulePermissions = validateModulePermission(loggedInUserRolePermission, module);
        const userHasRequiredRoleAndPermission = currentUser &&
            modulePermissions &&
            permissionTypes.some((type) => !!modulePermissions[type]);
        if (!userHasRequiredRoleAndPermission)
            return _jsx(Navigate, { to: "/statepage", state: { from: location }, replace: true });
        return children;
    }
    return _jsx(Loading, { isLoading: true });
};
export default ProtectedRoute;
