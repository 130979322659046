import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from "@application/common/helpers/translate";
import { getBoardsDropdownAsync, getSchoolsDropdownAsync, } from "@application/features/board/boardThunk";
import { resetSchoolTeachers } from "@application/features/school/schoolSlice";
import { getTeachersBySchoolIdAsync } from "@application/features/school/schoolThunk";
import { addVoucherAsync } from "@application/features/voucher/voucherThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const AddVoucher = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { schoolDropdownList } = useAppSelector((state) => state.board);
    const { schoolTeachers } = useAppSelector((state) => state.school);
    const { register, setError, setValue, formState: { errors }, control, handleSubmit, } = useForm({
        defaultValues: {
            boardId: "",
            schoolId: "",
            teachersIds: [],
        },
    });
    useEffect(() => {
        dispatch(resetSchoolTeachers());
        dispatch(getSchoolsDropdownAsync(false));
        dispatch(getBoardsDropdownAsync(false));
    }, [dispatch]);
    const onSubmit = (credentials) => {
        setIsLoading(true);
        credentials.boardId = selectedSchool?.boardId ?? "";
        credentials.schoolId = selectedSchool?.id ?? "";
        dispatch(addVoucherAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/vouchers");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("vouchers.voucher")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "voucher-form", noValidate: true, children: [_jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 4, children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedSchool, onChange: (event, newValue) => {
                                                    setValue("schoolId", newValue?.id ?? "");
                                                    setSelectedSchool(newValue);
                                                    setValue("teachersIds", []);
                                                    if (newValue)
                                                        dispatch(getTeachersBySchoolIdAsync(newValue?.id));
                                                    if (newValue !== null && newValue !== undefined) {
                                                        setError("schoolId", {
                                                            type: "required",
                                                            message: undefined,
                                                        });
                                                    }
                                                }, groupBy: (option) => option.boardName, options: schoolDropdownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("schools.school"), placeholder: translate("schools.school"), variant: "outlined", ...register("schoolId", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("schools.school")),
                                                        },
                                                    }), error: errors.schoolId && selectedSchool === null
                                                        ? true
                                                        : false })) }), _jsx(WnValidation, { fieldError: errors.schoolId })] }), _jsx(Grid, { item: true, lg: 8, xs: 12, children: _jsx(Controller, { name: "teachersIds", control: control, rules: {
                                                required: {
                                                    value: true,
                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("teachers&Supervisors.teacher")),
                                                },
                                            }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", multiple: true, filterSelectedOptions: true, options: schoolTeachers ?? [], value: value.map((teacherId) => schoolTeachers?.find((teacher) => teacher.id === teacherId)), isOptionEqualToValue: (option, value) => option?.id === value?.id, onChange: (_, selectedTeachers) => {
                                                    onChange(selectedTeachers?.map((teacher) => teacher?.id) ?? []);
                                                }, getOptionLabel: (option) => option?.name ?? "", renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("teachers&Supervisors.teachers"), placeholder: translate("teachers&Supervisors.teachers"), error: errors.teachersIds ? true : false, helperText: errors.teachersIds?.message })) })) }) })] }) }), _jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { disabled: isLoading, className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) }) }) })] }) })] }));
};
export default AddVoucher;
