import { wijzneusApi } from "./wijzneus-api";
const getVouchers = () => {
    return wijzneusApi.get(`vouchers`);
};
const getVoucher = (request) => {
    return wijzneusApi.get(`vouchers/${request}`);
};
const addVoucher = (request) => {
    return wijzneusApi.post(`vouchers`, request);
};
const enableDisableVoucher = (request) => {
    return wijzneusApi.patch(`vouchers`, request);
};
const VoucherApi = {
    getVouchers,
    getVoucher,
    addVoucher,
    enableDisableVoucher,
};
export default VoucherApi;
