import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PortalTypeStatusMap } from "@application/common/enums/portal-type";
import { translate } from "@application/common/helpers/translate";
import { addFaqAsync } from "@application/features/faq/faqThunk";
import { getPortalDropdownAsync } from "@application/features/portal/portalThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Autocomplete, Box, Button, Divider, Grid, IconButton, TextField, Typography, useTheme, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useEffect } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const AddFaq = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { portalDropdownList } = useAppSelector((state) => state.portal);
    const { register, control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {},
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "faqItems",
    });
    useEffect(() => {
        dispatch(getPortalDropdownAsync());
        onAddNewFaq();
    }, []);
    const onAddNewFaq = () => {
        append({
            question: "",
            answer: "",
        });
    };
    const onSubmit = (credentials) => {
        dispatch(addFaqAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/faqs");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.faqsDetails") }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "news-form", noValidate: true, children: [_jsx(Grid, { container: true, spacing: 2.5, sx: { mb: 3 }, children: _jsxs(Grid, { item: true, lg: 4, xs: 12, children: [portalDropdownList && (_jsx(Controller, { name: "portalId", control: control, rules: {
                                            required: {
                                                value: true,
                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("faqs.portal")),
                                            },
                                        }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: portalDropdownList, value: portalDropdownList.find((option) => option.id === value) || null, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionLabel: (option) => PortalTypeStatusMap.get(option.portalType), onChange: (_, value) => {
                                                onChange(value?.id ?? "");
                                            }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("faqs.portal"), placeholder: translate("faqs.portal"), error: errors.portalId ? true : false })) })) })), _jsx(WnValidation, { fieldError: errors.portalId })] }) }), _jsx(Divider, {}), _jsx(Typography, { variant: "h5", sx: { mt: 1, mb: 3 }, children: translate("faqs.faqs") }), fields.map((item, index) => (_jsx("div", { children: _jsx(Grid, { container: true, spacing: 3.5, marginBottom: 3, children: _jsx(Grid, { item: true, xs: 12, children: _jsxs(Box, { sx: {
                                            p: 2,
                                            borderRadius: "12px",
                                            border: "1px solid #87CEF3",
                                            position: "relative",
                                        }, children: [fields.length > 1 && (_jsx(IconButton, { size: "small", color: "error", sx: {
                                                    backgroundColor: theme.palette.white.main,
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                    transform: "translate(50%, -50%)",
                                                }, onClick: () => remove(index), children: _jsx("span", { className: "material-symbols-rounded", children: "delete" }) })), _jsxs(Grid, { container: true, rowSpacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { ...register(`faqItems.${index}.question`, {
                                                                required: {
                                                                    value: true,
                                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("faqs.question")),
                                                                },
                                                                // maxLength: {
                                                                //   value: 100,
                                                                //   message: "Maximum 100 characters are allowed.",
                                                                // },
                                                            }), id: "outlined-basic", label: `${index + 1}. ${translate("faqs.question")}`, placeholder: translate("faqs.question"), variant: "outlined", size: "small", fullWidth: true, error: errors.faqItems &&
                                                                !!errors.faqItems[index]?.question, helperText: errors.faqItems &&
                                                                errors.faqItems[index]?.question?.message }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { ...register(`faqItems.${index}.answer`, {
                                                                required: {
                                                                    value: true,
                                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("faqs.answer")),
                                                                },
                                                                // maxLength: {
                                                                //   value: 100,
                                                                //   message: "Maximum 100 characters are allowed.",
                                                                // },
                                                            }), id: "outlined-basic", label: translate("faqs.answer"), placeholder: translate("faqs.answer"), variant: "outlined", size: "small", multiline: true, fullWidth: true, rows: 3, error: errors.faqItems && !!errors.faqItems[index]?.answer, helperText: errors.faqItems &&
                                                                errors.faqItems[index]?.answer?.message }) })] })] }) }) }) }, `main-div-${item.id}`))), _jsx(Grid, { item: true, xs: 12, sx: { textAlign: "right" }, children: _jsx(Button, { variant: "text", color: "primary", className: "Button", onClick: () => onAddNewFaq(), children: translate("cta.addNewFaq") }) }), _jsx("div", { style: { textAlign: "right", marginTop: "24px" }, children: _jsx(Button, { variant: "contained", color: "primary", className: "Button", type: "submit", children: translate("cta.submit") }) })] }) })] }));
};
export default AddFaq;
