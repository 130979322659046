import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import "./style.scss";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import WNSwitch from "@ui/components/wn-switch";
import { DataGrid, } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { enableDisableThemeAsync, getDownloadAndViewThemeAsync, getThemesAsync, } from "@application/features/theme/themeThunk";
import { useEffect, useState } from "react";
import { AppConsts } from "@application/common/constants/app-consts";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WnNoRecordFound from "@ui/components/wn-no-record";
import WNLongMenu from "@ui/components/wn-long-menu";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
const Themes = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [imageFile, setImageFile] = useState(undefined);
    const { themes, status } = useAppSelector((state) => state.theme);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Themes]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getThemesAsync());
    }, [dispatch]);
    const viewTheme = (file) => {
        const fileName = file.split("/")[1];
        dispatch(getDownloadAndViewThemeAsync(fileName)).then((res) => {
            if (res.payload !== null &&
                res.payload !== undefined &&
                res.payload !== "") {
                const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                setImageFile(base64Data);
                handleClickOpen();
            }
        });
    };
    const downloadTheme = (file) => {
        if (file) {
            const fileName = file.split("/")[1];
            if (fileName) {
                dispatch(getDownloadAndViewThemeAsync(fileName)).then((res) => {
                    if (res.payload !== null &&
                        res.payload !== undefined &&
                        res.payload !== "") {
                        const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                        var a = document.createElement("a");
                        a.href = base64Data;
                        a.download = `FeatureImage.${fileName.split(".")[1]}`;
                        a.target = "_blank";
                        a.click();
                    }
                });
            }
            else {
                console.log("no file name");
            }
        }
        else {
            console.log("no file");
        }
    };
    const columns = [
        {
            field: "name",
            headerName: translate("themes.themeName").toUpperCase(),
            sortable: true,
            flex: 1,
            hideable: false,
        },
        {
            field: "featureImage",
            headerName: translate("common.featureImage").toUpperCase(),
            sortable: false,
            flex: 1,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsxs(_Fragment, { children: [_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => viewTheme(params.row.featuredImage), children: translate("cta.view") }), _jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => downloadTheme(params.row.featuredImage), children: translate("cta.download") })] })),
        },
        {
            field: "season",
            headerName: translate("themes.season").toUpperCase(),
            sortable: true,
            flex: 1,
            valueGetter: (params) => `${params.row.season.label}`,
        },
        // { field: 'fromDate', headerName: 'FROM', sortable: true, flex: 1, align: 'center', headerAlign: 'center',
        //   valueGetter: (params: GridValueGetterParams) =>
        //   `${formateDate(params.row.fromDate, DateFormatConstants.DMY)}`},
        // { field: 'toDate', headerName: 'TO', sortable: true, flex: 1, align: 'center', headerAlign: 'center',
        //   valueGetter: (params: GridValueGetterParams) =>
        //   `${formateDate(params.row.toDate, DateFormatConstants.DMY)}`
        // },
        {
            field: "totalCourses",
            headerName: translate("themes.totalCourses").toUpperCase(),
            sortable: true,
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${params.row.totalCourses}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            filterable: false,
            hideable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNSwitch, { style: {
                    alignItems: "center",
                    marginLeft: "unset",
                    marginRight: "unset",
                }, checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.id) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            flex: 1,
            filterable: false,
            hideable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(_Fragment, { children: _jsx(WNLongMenu, { options: options, onClick: (option) => onLongMenuClick(option, params.row) }, params.row.id) })),
        },
    ];
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableThemeAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getThemesAsync());
            }
        });
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const options = [
        ...(hasViewPermission || hasEditPermission
            ? [
                {
                    key: "Details",
                    label: translate("cta.details"),
                    route: "Details",
                    isActive: true,
                },
            ]
            : []),
        ...(hasViewPermission
            ? [
                {
                    key: "ViewLessons",
                    label: translate("cta.viewLessons"),
                    route: "ViewLessons",
                    isActive: true,
                },
            ]
            : []),
        // {
        //   key: 'Delete',
        //   label: 'Delete',
        //   route: 'Delete',
        //   isActive: true
        // }
    ];
    const onLongMenuClick = (selectedOption, row) => {
        switch (selectedOption) {
            case "Details":
                navigate(`${row.id}`);
                break;
            case "ViewLessons":
                navigate(`/admin/themes/${row.id}/lessons`);
                break;
            // case "Delete":
            //   (!row.isActive) ?
            //     dispatch(deleteThemeAsync(row.id)) :
            //     dispatch(addErrorNotification("Cannot delete active theme"));
            //   break;
            default:
                break;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("themes.themes") }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/themes/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: themes ?? [], columns: columns, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !themes.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            featureImage: hasViewPermission ?? true,
                            isActive: hasEditPermission ?? true,
                            "": (hasEditPermission || hasViewPermission) ?? true,
                        } }, "theme-grid") }) }), _jsxs(BootstrapDialog, { onClose: handleClose, "aria-labelledby": "customized-dialog-title", open: open, children: [_jsx(IconButton, { "aria-label": "close", onClick: handleClose, sx: {
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }, children: _jsx(CloseIcon, {}) }), _jsx(DialogContent, { children: imageFile && _jsx("img", { src: imageFile, width: "100%", alt: "no-img-file" }) })] })] }));
};
export default Themes;
