import { translate } from "../helpers/translate";
export var CourseItemCategory;
(function (CourseItemCategory) {
    CourseItemCategory[CourseItemCategory["Video"] = 0] = "Video";
    CourseItemCategory[CourseItemCategory["Audio"] = 1] = "Audio";
    CourseItemCategory[CourseItemCategory["Picture"] = 2] = "Picture";
    CourseItemCategory[CourseItemCategory["Miscellaneous"] = 3] = "Miscellaneous";
    CourseItemCategory[CourseItemCategory["ReadingSheet"] = 4] = "ReadingSheet";
    CourseItemCategory[CourseItemCategory["WorkSheet"] = 5] = "WorkSheet";
    CourseItemCategory[CourseItemCategory["Craft"] = 6] = "Craft";
    CourseItemCategory[CourseItemCategory["Language"] = 7] = "Language";
    CourseItemCategory[CourseItemCategory["Calculate"] = 8] = "Calculate";
})(CourseItemCategory || (CourseItemCategory = {}));
export const CourseItemCategoryStatusMap = new Map([
    [CourseItemCategory.Video, translate("enums.courseItemCategory.video")],
    [CourseItemCategory.Audio, translate("enums.courseItemCategory.audio")],
    [CourseItemCategory.Picture, translate("enums.courseItemCategory.picture")],
    [CourseItemCategory.Miscellaneous, translate("enums.courseItemCategory.miscellaneous")],
    [CourseItemCategory.ReadingSheet, translate("enums.courseItemCategory.readingSheet")],
    [CourseItemCategory.WorkSheet, translate("enums.courseItemCategory.workSheet")],
    [CourseItemCategory.Craft, translate("enums.courseItemCategory.craft")],
    [CourseItemCategory.Language, translate("enums.courseItemCategory.language")],
    [CourseItemCategory.Calculate, translate("enums.courseItemCategory.calculate")],
]);
