import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import GoalApi from "@infrastructure/goal-api";
import { addSuccessNotification } from "../common/notificationSlice";
import { translate } from "@application/common/helpers/translate";
export const getGoalsAsync = createAsyncThunk("goal/getGoals", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await GoalApi.getGoals();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getGoalByIdAsync = createAsyncThunk("goal/getGoal", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await GoalApi.getGoal(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getSubGoalsByGoalIdAsync = createAsyncThunk("goal/getSubGoalsByGoalId", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await GoalApi.getSubGoalByGoalId(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getGoalDropdownAsync = createAsyncThunk("goal/getGoalDropdown", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await GoalApi.getGoalDropdown();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addGoalAsync = createAsyncThunk("goal/addGoal", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await GoalApi.addGoal(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("goals.goal"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateGoalAsync = createAsyncThunk("goal/updateGoal", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await GoalApi.updateGoal(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("goals.goal"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableGoalAsync = createAsyncThunk("goal/enableDisableGoal", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await GoalApi.enableDisableGoal(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("goals.goal"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
