import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import FaqApi from "@infrastructure/faq-api";
import { translate } from "@application/common/helpers/translate";
export const getFaqsAsync = createAsyncThunk("faq/getFaqs", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FaqApi.getFaqs();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getFaqByPortalAsync = createAsyncThunk("faq/getFaqByPortal", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FaqApi.getFaqByPortal(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getAdminPortalFaqsAsync = createAsyncThunk("faq/getFaqsByAdminPortal", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FaqApi.getAdminPortalFaqs();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addFaqAsync = createAsyncThunk("faq/addFaq", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await FaqApi.addFaq(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("faqs.faqs"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateFaqAsync = createAsyncThunk("faq/updateFaq", async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await FaqApi.updateFaq(request);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("faqs.faqs"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableFaqAsync = createAsyncThunk("faq/enableDisableFaq", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await FaqApi.enableDisableFaq(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("faqs.faqs"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const deleteFaqAsync = createAsyncThunk("faq/deleteFaq", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await FaqApi.deleteFaq(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.deleteModuleSuccess").replace("{module}", translate("faqs.faqs"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
