import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { addSubGoalAsync, enableDisableSubGoalAsync, getSubGoalByIdAsync, getSubGoalDropdownAsync, getSubGoalsAsync, updateSubGoalAsync } from './subGoalThunk';
const initialState = {
    status: APIStatus.IDLE,
    subGoals: [],
    subGoalDropdownList: []
};
export const subGoalSlice = createSlice({
    name: 'subGoal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubGoalsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSubGoalsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.subGoals = action.payload;
        })
            .addCase(getSubGoalsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSubGoalByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSubGoalByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.subGoal = action.payload;
        })
            .addCase(getSubGoalByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSubGoalDropdownAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSubGoalDropdownAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.subGoalDropdownList = action.payload;
        })
            .addCase(getSubGoalDropdownAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addSubGoalAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addSubGoalAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addSubGoalAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateSubGoalAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateSubGoalAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateSubGoalAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableSubGoalAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableSubGoalAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableSubGoalAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = subGoalSlice.actions;
export default subGoalSlice.reducer;
