import { wijzneusApi } from "./wijzneus-api";
const getFaqs = () => {
    return wijzneusApi.get(`faqs`);
};
const getFaqByPortal = (request) => {
    return wijzneusApi.get(`faqs/${request}`);
};
const getAdminPortalFaqs = () => {
    return wijzneusApi.get(`faqs/GetFaqsForAdmin`);
};
const addFaq = (request) => {
    return wijzneusApi.post(`faqs`, request);
};
const updateFaq = (request) => {
    return wijzneusApi.put(`faqs`, request);
};
const enableDisableFaq = (request) => {
    return wijzneusApi.patch(`faqs`, request);
};
const deleteFaq = (request) => {
    return wijzneusApi.delete(`faqs/${request}`);
};
const FaqApi = {
    getFaqs,
    getFaqByPortal,
    addFaq,
    updateFaq,
    enableDisableFaq,
    deleteFaq,
    getAdminPortalFaqs
};
export default FaqApi;
