import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import RolePermissionApi from "@infrastructure/role-permission-api";
import { translate } from "@application/common/helpers/translate";
export const getRolePermissionByIdAsync = createAsyncThunk("rolePermission/getRolePermissionsById", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await RolePermissionApi.getRolePermissionsById(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getRolePermissionByNameAsync = createAsyncThunk("rolePermission/getRolePermissionsByName", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await RolePermissionApi.getRolePermissionsByName(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addRolePermissionsAsync = createAsyncThunk("rolePermission/addRolePermissions", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await RolePermissionApi.addRolePermissions(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("permissions.permissions"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateRolePermissionsAsync = createAsyncThunk("rolePermissions/updateRolePermissions", async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await RolePermissionApi.updateRolePermissions(request);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("permissions.permission"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableRolePermissionsAsync = createAsyncThunk("rolePermission/enableDisableRolePermissions", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await RolePermissionApi.enableDisableRolePermissions(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("permissions.permissions"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
