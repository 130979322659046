import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import RoleApi from "@infrastructure/role-api";
import { translate } from "@application/common/helpers/translate";
export const getRolesAsync = createAsyncThunk("role/getRoles", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await RoleApi.getRoles();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getRoleByIdAsync = createAsyncThunk("role/getRole", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await RoleApi.getRole(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getRoleDropDownAsync = createAsyncThunk("board/getRoleDropDown", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await RoleApi.getRoleDropdown();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addRoleAsync = createAsyncThunk("role/addRole", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await RoleApi.addRole(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("account.role"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateRoleAsync = createAsyncThunk("role/updateRole", async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await RoleApi.updateRole(request);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("account.role"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableRoleAsync = createAsyncThunk("role/enableDisableRole", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await RoleApi.enableDisableRole(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("account.role"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
