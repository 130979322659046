import { wijzneusApi } from './wijzneus-api';
const getTeachers = () => {
    return wijzneusApi.get(`teachers`);
};
const getTeacher = (request) => {
    return wijzneusApi.get(`teachers/${request}`);
};
const addTeacher = (request) => {
    return wijzneusApi.post(`teachers`, request);
};
const updateTeacher = (request) => {
    return wijzneusApi.put(`teachers`, request);
};
const enableDisableTeacher = (request) => {
    return wijzneusApi.patch(`teachers`, request);
};
const TeacherApi = {
    getTeachers,
    getTeacher,
    addTeacher,
    updateTeacher,
    enableDisableTeacher
};
export default TeacherApi;
