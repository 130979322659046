import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import SubGoalApi from "@infrastructure/sub-goal-api";
import { addSuccessNotification } from "../common/notificationSlice";
import { translate } from "@application/common/helpers/translate";
export const getSubGoalsAsync = createAsyncThunk("subGoal/getSubGoals", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SubGoalApi.getSubGoals();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getSubGoalByIdAsync = createAsyncThunk("subGoal/getSubGoal", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SubGoalApi.getSubGoal(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addSubGoalAsync = createAsyncThunk("subGoal/addSubGoal", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await SubGoalApi.addSubGoal(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("subGoals.subGoal"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateSubGoalAsync = createAsyncThunk("subGoal/updateSubGoal", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await SubGoalApi.updateSubGoal(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("subGoals.subGoal"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableSubGoalAsync = createAsyncThunk("subGoal/enableDisableSubGoal", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await SubGoalApi.enableDisableSubGoal(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("subGoals.subGoal"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getSubGoalDropdownAsync = createAsyncThunk("subGoal/getSubGoalDropdown", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SubGoalApi.getSubGoalDropdownList();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
