import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { translate } from "@application/common/helpers/translate";
const DashboardActiveSchools = ({ activeSchoolsCount, totalSchoolsCount, }) => {
    const percentage = (activeSchoolsCount / totalSchoolsCount) * 100;
    const pieChartOptions = {
        chart: {
            type: "pie",
        },
        title: {
            text: "Active School Distribution",
        },
        series: [
            {
                name: "Schools",
                colorByPoint: true,
                data: [
                    {
                        name: "Active Schools",
                        y: activeSchoolsCount,
                    },
                    {
                        name: "Inactive Schools",
                        y: totalSchoolsCount - activeSchoolsCount,
                    },
                ],
            },
        ],
    };
    const theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", sx: { mb: 1 }, children: translate("dashboard.activeSchools") }), _jsx(Typography, { variant: "body1", sx: { fontSize: 70, fontWeight: "500" }, children: activeSchoolsCount }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 3 }, children: [_jsxs(Box, { sx: { position: "relative" }, children: [_jsx(CircularProgress, { sx: { color: theme.palette.common.white }, size: 90, variant: "determinate", value: percentage }), _jsx(Box, { sx: {
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }, children: _jsx(Typography, { variant: "caption", component: "div", children: `${Math.round(percentage)}%` }) })] }), _jsxs(Box, { children: [_jsx(Typography, { sx: { fontSize: 24, fontWeight: "500" }, children: totalSchoolsCount }), _jsx(Typography, { sx: { fontSize: 24, fontWeight: "500" }, children: translate("dashboard.total") })] })] })] }));
};
export default DashboardActiveSchools;
