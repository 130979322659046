import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Typography, useMediaQuery, } from "@mui/material";
import "./style.scss";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { enableDisableSubGoalAsync, getSubGoalsAsync, } from "@application/features/sub-goal/subGoalThunk";
import WNSwitch from "@ui/components/wn-switch";
import { addWarningNotification } from "@application/features/common/notificationSlice";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
const SubGoals = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const { subGoals } = useAppSelector((state) => state.subGoal);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module["Sub-Goals"]]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getSubGoalsAsync());
    }, [dispatch]);
    const onEnableDisable = ($event, id, subGoal) => {
        if ($event) {
            if (!subGoal.goal.isActive) {
                dispatch(addWarningNotification(translate("validations.subGoals.activateMainGoal")));
                return;
            }
        }
        dispatch(enableDisableSubGoalAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getSubGoalsAsync());
            }
        });
    };
    const onDetailsClick = (subGoal) => {
        navigate(`${subGoal.id}`);
    };
    const columns = [
        {
            field: "name",
            headerName: translate("subGoals.subGoalName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
            hideable: false,
        },
        {
            field: "goal",
            headerName: translate("subGoals.mainGoal").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
            valueGetter: (params) => `${params.row.goal.name}`,
        },
        {
            field: "totalCourses",
            headerName: translate("subGoals.currentCourses").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            align: "center",
            headerAlign: "center",
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.id, params.row) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => onDetailsClick(params.row), children: translate("cta.details") })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("subGoals.subGoals") }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/sub-goals/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: subGoals ?? [], columns: columns, "columnHeader--showColumnBorder": false, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !subGoals?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                            "": (hasViewPermission || hasEditPermission) ?? true,
                        } }, "goal-grid") }) })] }));
};
export default SubGoals;
