import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { addVoucherAsync, enableDisableVoucherAsync, getVoucherByIdAsync, getVouchersAsync, } from "./voucherThunk";
const initialState = {
    status: APIStatus.IDLE,
};
export const voucherSlice = createSlice({
    name: "voucher",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getVouchersAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getVouchersAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.vouchers = action.payload;
        })
            .addCase(getVouchersAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getVoucherByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getVoucherByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            // state.voucher = action.payload;
        })
            .addCase(getVoucherByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addVoucherAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addVoucherAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addVoucherAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableVoucherAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableVoucherAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableVoucherAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    },
});
export const {} = voucherSlice.actions;
export default voucherSlice.reducer;
