import { translate } from "../helpers/translate";
export var CourseInstructionType;
(function (CourseInstructionType) {
    CourseInstructionType[CourseInstructionType["ExternalLink"] = 0] = "ExternalLink";
    CourseInstructionType[CourseInstructionType["UploadAttachment"] = 1] = "UploadAttachment";
})(CourseInstructionType || (CourseInstructionType = {}));
export const CourseInstructionStatusMap = new Map([
    [
        CourseInstructionType.ExternalLink,
        translate("enums.courseInstructionType.externalLink"),
    ],
    [
        CourseInstructionType.UploadAttachment,
        translate("enums.courseInstructionType.uploadAttachment"),
    ],
]);
