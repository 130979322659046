import { wijzneusApi } from "./wijzneus-api";
const getRolePermissionsById = (request) => {
    return wijzneusApi.get(`rolePermissions/byId/${request}`, {
        headers: {
            "Accept-Language": "nl",
        },
    });
};
const getRolePermissionsByName = (request) => {
    return wijzneusApi.get(`rolePermissions/byName/${request}`);
};
const addRolePermissions = (request) => {
    return wijzneusApi.post(`rolePermissions`, request);
};
const updateRolePermissions = (request) => {
    return wijzneusApi.put(`rolePermissions`, request);
};
const enableDisableRolePermissions = (request) => {
    return wijzneusApi.patch(`rolePermissions`, request);
};
const RolePermissionApi = {
    getRolePermissionsById,
    getRolePermissionsByName,
    addRolePermissions,
    updateRolePermissions,
    enableDisableRolePermissions,
};
export default RolePermissionApi;
