import { wijzneusApi } from "./wijzneus-api";
const getRoles = () => {
    return wijzneusApi.get(`roles`, {
        headers: {
            "Accept-Language": "nl",
        },
    });
};
const getRole = (request) => {
    return wijzneusApi.get(`roles/${request}`);
};
const getRoleDropdown = () => {
    return wijzneusApi.get(`roles/dropdown`);
};
const addRole = (request) => {
    return wijzneusApi.post(`roles`, request);
};
const updateRole = (request) => {
    return wijzneusApi.put(`roles`, request);
};
const enableDisableRole = (request) => {
    return wijzneusApi.patch(`roles`, request);
};
const RoleApi = {
    getRoles,
    getRole,
    getRoleDropdown,
    addRole,
    updateRole,
    enableDisableRole,
};
export default RoleApi;
