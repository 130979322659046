import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import VoucherApi from "@infrastructure/voucher-api";
import { translate } from "@application/common/helpers/translate";
export const getVouchersAsync = createAsyncThunk("voucher/getVouchers", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await VoucherApi.getVouchers();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getVoucherByIdAsync = createAsyncThunk("voucher/getVoucher", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await VoucherApi.getVoucher(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addVoucherAsync = createAsyncThunk("voucher/addVoucher", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await VoucherApi.addVoucher(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("vouchers.voucher"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableVoucherAsync = createAsyncThunk("voucher/enableDisableVoucher", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await VoucherApi.enableDisableVoucher(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("vouchers.voucher"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
