import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import ThemeApi from "@infrastructure/theme-api";
import { translate } from "@application/common/helpers/translate";
export const getThemesAsync = createAsyncThunk("theme/getThemes", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await ThemeApi.getThemes();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getThemeDropdownListAsync = createAsyncThunk("theme/getThemeDropdownList", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await ThemeApi.getThemeDropdownList();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getThemeByIdAsync = createAsyncThunk("theme/getTheme", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await ThemeApi.getTheme(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getCoursesByThemeIdAsync = createAsyncThunk("theme/getCoursesByThemeId", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await ThemeApi.getCoursesByThemeId(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addThemeAsync = createAsyncThunk("theme/addTheme", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await ThemeApi.addTheme(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("themes.theme"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateThemeAsync = createAsyncThunk("theme/updateTheme", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await ThemeApi.updateTheme(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("themes.theme"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableThemeAsync = createAsyncThunk("theme/enableDisableTheme", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await ThemeApi.enableDisableTheme(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("themes.theme"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getDownloadAndViewThemeAsync = createAsyncThunk("theme/getDownloadAndViewTheme", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await ThemeApi.downloadAndViewTheme(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const deleteThemeAsync = createAsyncThunk("theme/deleteTheme", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await ThemeApi.deleteTheme(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.deleteModuleSuccess").replace("{module}", translate("themes.theme"))));
            dispatch(getThemesAsync());
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
