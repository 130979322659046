import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, IconButton, Tooltip, Typography, useMediaQuery, } from "@mui/material";
import "./style.scss";
import { Add, Key, LockReset } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect, useState } from "react";
import WNSwitch from "@ui/components/wn-switch";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { enableDisableUserAsync, getUsersAsync, } from "@application/features/user/userThunk";
import { RoleEnum } from "@application/common/enums/role";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
import AlertDialog from "@ui/components/wn-dialog";
import { userForgotPasswordAsync } from "@application/features/auth/authThunk";
import { setOpenDialog } from "@application/features/user/userSlice";
const Users = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { users, isOpenDialog } = useAppSelector((state) => state.user);
    const { currentUser } = useAppSelector((state) => state.auth);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Users]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    //const [openDialog, setOpenDialog] = useState(false);
    const [userForgotPassword, setUserForgotPassword] = useState();
    const handleOpenDialog = (params) => {
        setUserForgotPassword({ id: params.id });
        dispatch(setOpenDialog(true));
    };
    const handleCloseDialog = () => {
        dispatch(setOpenDialog(false));
    };
    const handleConfirmAction = () => {
        if (userForgotPassword) {
            dispatch(userForgotPasswordAsync(userForgotPassword));
        }
        handleCloseDialog();
    };
    useEffect(() => {
        dispatch(getUsersAsync());
    }, [dispatch]);
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableUserAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getUsersAsync());
            }
        });
    };
    const onDetailsClick = (user) => {
        navigate(`${user.id}`);
    };
    const onChangePasswordClick = (user) => {
        navigate(`changePassword/${user.id}`);
    };
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "firstName",
            headerName: translate("account.firstName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "lastName",
            headerName: translate("account.lastName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "email",
            headerName: translate("account.email").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        // {
        //   field: "phoneNumber",
        //   headerName: translate("account.phoneNumber").toUpperCase(),
        //   sortable: false,
        //   hideable: false,
        // },
        {
            field: "roleName",
            headerName: translate("account.role").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
            valueGetter: (params) => {
                const roleName = params.row.role.label;
                const translatedRoleName = translate(`teachers&Supervisors.${roleName.toLowerCase()}`);
                return translatedRoleName;
            },
        },
        {
            field: "passwordReset",
            headerName: translate("account.passwordReset").toUpperCase(),
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            renderCell: (params) => (_jsx(Tooltip, { title: translate("account.passwordReset"), arrow: true, placement: "top", children: _jsx(IconButton, { size: "medium", color: "primary", children: _jsx(Key, { onClick: () => handleOpenDialog(params.row) }) }) })),
        },
        {
            field: "changePassword",
            headerName: translate("account.changePassword").toUpperCase(),
            sortable: false,
            align: "center",
            headerAlign: "center",
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            renderCell: (params) => (_jsx(Tooltip, { title: translate("account.changePassword"), arrow: true, placement: "top", children: _jsx(IconButton, { size: "medium", color: "primary", children: _jsx(LockReset, { onClick: () => onChangePasswordClick(params.row) }) }) })),
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.id), disable: params.row.role?.label == RoleEnum[RoleEnum.SuperAdmin] }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            renderCell: (params) => (_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => onDetailsClick(params.row), children: translate("cta.details") })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("users.users") }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/users/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: users?.filter((user) => {
                            // Exclude SuperAdmin user for non-super admin users
                            if (currentUser?.role != RoleEnum[RoleEnum.SuperAdmin] &&
                                user?.role?.label == RoleEnum[RoleEnum.SuperAdmin])
                                return false;
                            return true;
                        }) ?? [], columns: columns, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !users?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            passwordReset: (currentUser?.role == RoleEnum[RoleEnum.SuperAdmin] ||
                                currentUser?.role == RoleEnum[RoleEnum.Admin]) ??
                                true,
                            isActive: hasEditPermission ?? true,
                            "": (hasViewPermission || hasEditPermission) ?? true,
                        } }, "user-grid") }) }), _jsx(AlertDialog, { open: isOpenDialog, onClose: handleCloseDialog, title: translate("resetPasswordDialog.title"), content: translate("resetPasswordDialog.content"), onConfirm: handleConfirmAction, onCancel: handleCloseDialog, confirmButtonText: translate("cta.sendEmail"), cancelButtonText: translate("cta.cancel") })] }));
};
export default Users;
