import { translate } from "../helpers/translate";
export var AgreementStatus;
(function (AgreementStatus) {
    AgreementStatus[AgreementStatus["Sent"] = 0] = "Sent";
    AgreementStatus[AgreementStatus["Received"] = 1] = "Received";
})(AgreementStatus || (AgreementStatus = {}));
export const AgreementStatusResultMap = new Map([
    [AgreementStatus.Sent, translate("enums.agreementStatus.sent")],
    [AgreementStatus.Received, translate("enums.agreementStatus.received")],
]);
