import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import AgreementApi from "@infrastructure/agreement-api";
import { translate } from "@application/common/helpers/translate";
export const getAgreementsAsync = createAsyncThunk("agreements/getAgreements", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AgreementApi.getAgreements();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getAgreementByIdAsync = createAsyncThunk("agreements/getAgreement", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AgreementApi.getAgreement(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addAgreementAsync = createAsyncThunk("agreements/addAgreement", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await AgreementApi.addAgreement(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("agreements.agreement"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateAgreementAsync = createAsyncThunk("agreements/updateAgreement", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await AgreementApi.updateAgreement(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("agreements.agreement"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getDownloadAndViewAgreementAsync = createAsyncThunk("agreements/getDownloadAndViewAgreement", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AgreementApi.downloadAndViewAgreement(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
