import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Button, Grid, IconButton, Link, Container, } from "@mui/material";
import "./style.scss";
import Logo from "../../../../logo.svg";
import { Bars2Icon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { logout } from "@application/features/auth/authSlice";
import { toggleMenu } from "@application/features/common/toggleSlice";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import { useNavigate } from "react-router-dom";
import { translate } from "@application/common/helpers/translate";
import { Icon } from "@iconify/react";
import { RoleEnum } from "@application/common/enums/role";
function Header() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { currentUser } = useAppSelector((state) => state.auth);
    return (_jsx("header", { children: _jsx(Container, { maxWidth: "xl", children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", sx: { padding: "1rem 24px", backgroundColor: "white.main" }, children: [_jsx(Grid, { item: true, sm: "auto", children: _jsxs(Box, { sx: { display: "flex" }, gap: 2, children: [_jsx(IconButton, { onClick: () => dispatch(toggleMenu()), sx: { backgroundColor: "inputBg.main" }, "aria-label": "humburger", children: _jsx(Bars2Icon, { width: 36, height: 36 }) }), _jsx(Link, { sx: { display: "block" }, children: _jsx("img", { height: 50, src: Logo, alt: "logo" }) })] }) }), _jsx(Grid, { item: true, sm: "auto", children: _jsxs(Box, { sx: { display: "flex", gap: "1rem" }, children: [currentUser?.role == RoleEnum[RoleEnum.SuperAdmin] && (_jsx(Button, { LinkComponent: Link, className: "Button", onClick: () => window.open("https://www.wijzneus.nl/?edit", "_blank"), variant: "outlined", color: "success", startIcon: _jsx(Icon, { icon: "fa6-brands:webflow" }), children: translate("menu.webCMS") })), _jsx(Button, { LinkComponent: Link, className: "Button", onClick: () => window.open("https://www.wijzneus.nl", "_blank"), variant: "outlined", color: "success", startIcon: _jsx(Icon, { icon: "solar:home-smile-bold" }), children: translate("menu.home") }), _jsx(Button, { LinkComponent: Link, className: "Button", onClick: () => window.open("https://portaal.wijzneus.nl", "_blank"), variant: "outlined", color: "primary", startIcon: _jsx(ChildCareIcon, {}), children: translate("menu.kidsPortal") }), _jsxs(Button, { LinkComponent: Link, className: "Button", onClick: () => {
                                        dispatch(logout());
                                        navigate("/login");
                                    }, variant: "outlined", color: "error", children: [_jsx("span", { className: "material-symbols-rounded", children: "logout" }), translate("menu.logout")] }), _jsx(Avatar, { alt: currentUser?.name, src: "/static/images/avatar/1.jpg" })] }) })] }) }) }));
}
export default Header;
