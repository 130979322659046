import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./style.scss";
import logo from "../../../../logo.svg";
import loginImage from "../../../../login-feature-nose.png";
import { Alert, Button, Container, Grid, TextField, Typography, } from "@mui/material";
import { forgotPasswordAsync } from "@application/features/auth/authThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Controller, useForm } from "react-hook-form";
import { translate } from "@application/common/helpers/translate";
import WnValidation from "@ui/components/wn-validation";
import { useEffect } from "react";
import { clearApiError } from "@application/features/auth/authSlice";
function ForgetPassword() {
    const { apiError } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            email: "",
        },
    });
    useEffect(() => {
        dispatch(clearApiError());
    }, []);
    const onSubmit = (credentials) => dispatch(forgotPasswordAsync(credentials));
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: logo, className: "login-logo", height: 90, alt: "logo" }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "fields", children: _jsxs(Container, { sx: { width: "80%" }, children: [_jsx(Typography, { variant: "h4", sx: {
                                        fontWeight: "700",
                                        color: "winter.main",
                                        marginBottom: "40px",
                                    }, children: translate("account.forgotPassword") }), apiError && apiError.status === 401 && (_jsxs(_Fragment, { children: [_jsx(Alert, { severity: "error", children: apiError.error }), _jsx("br", {})] })), _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "forget-password-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 4, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Controller, { name: "email", control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.email")),
                                                            },
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: translate("validations.invalidEmailAddress"),
                                                            },
                                                        }, render: ({ field: { onChange, value } }) => (_jsx(TextField, { size: "small", InputLabelProps: { shrink: true }, type: "email", id: "email", label: translate("account.email"), variant: "outlined", placeholder: translate("account.emailPlaceholder"), onChange: onChange, value: value, sx: { width: "100%" } })) }), _jsx(WnValidation, { fieldError: errors.email })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Button, { className: "Button", variant: "contained", color: "customDark", disableElevation: true, size: "large", sx: { width: "100%" }, type: "submit", children: translate("cta.submit") }) })] }) })] }) }), _jsx("div", { className: "feature", children: _jsx("img", { src: loginImage, style: { maxWidth: 400 }, height: "85%", alt: "feature" }) })] })] }));
}
export default ForgetPassword;
