import { translate } from "../helpers/translate";
export var LicenseInvoiceType;
(function (LicenseInvoiceType) {
    LicenseInvoiceType[LicenseInvoiceType["Management"] = 0] = "Management";
    LicenseInvoiceType[LicenseInvoiceType["School"] = 1] = "School";
})(LicenseInvoiceType || (LicenseInvoiceType = {}));
export const LicenseInvoiceTypeStatusMap = new Map([
    [
        LicenseInvoiceType.Management,
        translate("enums.licenseInvoiceType.management"),
    ],
    [LicenseInvoiceType.School, translate("enums.licenseInvoiceType.school")],
]);
