import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import WNSwitch from "@ui/components/wn-switch";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect } from "react";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { getFaqsAsync, enableDisableFaqAsync, } from "@application/features/faq/faqThunk";
import "./style.scss";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
import { PortalTypeStatusMap } from "@application/common/enums/portal-type";
const Faqs = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { faqs } = useAppSelector((state) => state.faq);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.FAQs]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getFaqsAsync());
    }, [dispatch]);
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableFaqAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getFaqsAsync());
            }
        });
    };
    const onDetailsClick = (faq) => {
        navigate(`${faq.portal.id}`);
    };
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "portal",
            headerName: translate("faqs.portal").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => PortalTypeStatusMap.get(params.row.portal.portalType),
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.portal.id) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            renderCell: (params) => (_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => onDetailsClick(params.row), children: translate("cta.details") })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex", gap: 0.5 }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("faqs.faqs") }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/faqs/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: faqs ?? [], columns: columns, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !faqs?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                            "": (hasViewPermission || hasEditPermission) ?? true,
                        } }, "faq-grid") }) })] }));
};
export default Faqs;
