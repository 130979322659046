import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Collapse, } from "@mui/material";
import "./style.scss";
import { useState } from "react";
import { useAppSelector } from "@application/store/useStore";
import { NavLink } from "react-router-dom";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
function Sidebar() {
    const { isToggle } = useAppSelector((state) => state.toggle);
    const { currentUser } = useAppSelector((state) => state.auth);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const [openUsersAndPermissions, setUsersAndPermissionsOpen] = useState(false);
    const handleUsersAndPermissionsClick = () => {
        setUsersAndPermissionsOpen(!openUsersAndPermissions);
    };
    const hasViewPermissionForModule = (module) => {
        const modulePermissions = loggedInUserRolePermission &&
            validateModulePermission(loggedInUserRolePermission, module);
        return modulePermissions
            ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
            : false;
    };
    // Arrays for modules before dividers
    const firstSectionModules = [
        Module[Module.Dashboard],
        Module[Module.Boards],
        Module[Module.Schools],
        Module[Module["Teachers & Supervisors"]],
    ];
    const secondSectionModules = [
        Module[Module.Goals],
        Module[Module["Sub-Goals"]],
        Module[Module.Themes],
        Module[Module.Lessons],
        Module[Module.Vouchers],
    ];
    const thirdSectionModules = [Module[Module.News], Module[Module.FAQs]];
    const fourthSectionModules = [
        Module[Module.Agreements],
        //Module[Module.Invoices],
        Module[Module.Users],
        Module[Module["Role Permissions"]],
    ];
    // Function to check if at least one module in the array has view permission
    const atLeastOneModuleHasViewPermission = (modulesArray) => {
        return modulesArray.some((module) => hasViewPermissionForModule(module));
    };
    return (_jsx("aside", { children: _jsxs(Box, { className: isToggle ? "sidebar-collapsed" : "sidebar-open", role: "presentation", children: [_jsxs(List, { children: [loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Dashboard]) && (_jsx(NavLink, { to: "admin/dashboard", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded fill", children: translate("dashboard.dashboard") }) }), _jsx(ListItemText, { primary: `Dashboard`, sx: { whiteSpace: "nowrap" } })] }) }, "Dashboard") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Boards]) && (_jsx(NavLink, { to: "admin/boards", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded fill", children: "corporate_fare" }) }), _jsx(ListItemText, { primary: translate("boards.boards"), sx: { whiteSpace: "nowrap" } })] }) }, "Boards") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Schools]) && (_jsx(NavLink, { to: "admin/schools", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded fill", children: "school" }) }), _jsx(ListItemText, { primary: translate("schools.schools"), sx: { whiteSpace: "nowrap" } })] }) }, "Schools") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module["Teachers & Supervisors"]]) && (_jsx(NavLink, { to: "admin/teachers", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded fill", children: "local_library" }) }), _jsx(ListItemText, { primary: translate("teachers&Supervisors.teachers&Supervisors"), sx: { whiteSpace: "nowrap" } })] }) }, "Teachers & Supervisors") }))] }), (atLeastOneModuleHasViewPermission(secondSectionModules) ||
                    atLeastOneModuleHasViewPermission(thirdSectionModules) ||
                    atLeastOneModuleHasViewPermission(fourthSectionModules)) && (_jsx(Divider, {})), atLeastOneModuleHasViewPermission(secondSectionModules) && (_jsxs(List, { children: [loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Goals]) && (_jsx(NavLink, { to: "admin/goals", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "account_box" }) }), _jsx(ListItemText, { primary: translate("goals.goals"), sx: { whiteSpace: "nowrap" } })] }) }, "goals") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module["Sub-Goals"]]) && (_jsx(NavLink, { to: "admin/sub-goals", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "flag_circle" }) }), _jsx(ListItemText, { primary: translate("subGoals.subGoals"), sx: { whiteSpace: "nowrap" } })] }) }, "subgoals") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Themes]) && (_jsx(NavLink, { to: "admin/themes", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "celebration" }) }), _jsx(ListItemText, { primary: translate("themes.themes"), sx: { whiteSpace: "nowrap" } })] }) }, `Themes`) })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Lessons]) && (_jsx(NavLink, { to: "admin/lessons", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "auto_stories" }) }), _jsx(ListItemText, { primary: translate("lessons.lessons"), sx: { whiteSpace: "nowrap" } })] }) }, `Lessons`) })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Vouchers]) && (_jsx(NavLink, { to: "admin/vouchers", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "data_array" }) }), _jsx(ListItemText, { primary: translate("vouchers.vouchers"), sx: { whiteSpace: "nowrap" } })] }) }, "Vouchers") }))] })), atLeastOneModuleHasViewPermission(secondSectionModules) && _jsx(Divider, {}), atLeastOneModuleHasViewPermission(thirdSectionModules) && (_jsxs(List, { children: [loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.News]) && (_jsx(NavLink, { to: "admin/news", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "full_coverage" }) }), _jsx(ListItemText, { primary: translate("news.news"), sx: { whiteSpace: "nowrap" } })] }) }, `News`) })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.FAQs]) && (_jsx(NavLink, { to: "admin/faqs", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "help" }) }), _jsx(ListItemText, { primary: translate("faqs.faqs"), sx: { whiteSpace: "nowrap" } })] }) }, `FAQs`) }))] })), atLeastOneModuleHasViewPermission(thirdSectionModules) && _jsx(Divider, {}), _jsxs(List, { children: [loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Agreements]) && (_jsx(NavLink, { to: "admin/agreements", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "handshake" }) }), _jsx(ListItemText, { primary: translate("agreements.agreements"), sx: { whiteSpace: "nowrap" } })] }) }, `Agreements`) })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            (hasViewPermissionForModule(Module[Module.Users]) ||
                                hasViewPermissionForModule(Module[Module["Role Permissions"]])) && (_jsxs(_Fragment, { children: [_jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                            borderRadius: "12px",
                                            paddingTop: "4px",
                                            paddingBottom: "4px",
                                        }, 
                                        // disabled={
                                        //   loggedInUserRolePermission &&
                                        //   loggedInUserRolePermission.length > 0 &&
                                        //   !hasViewPermissionForModule(Module[Module.Users]) &&
                                        //   !hasViewPermissionForModule(
                                        //     Module[Module["Role Permissions"]]
                                        //   )
                                        // }
                                        onClick: handleUsersAndPermissionsClick, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "lock" }) }), _jsx(ListItemText, { primary: translate("permissions.users&Permissions"), sx: { whiteSpace: "nowrap" } }), openUsersAndPermissions ? (_jsx("span", { className: "material-symbols-rounded", style: { float: "right" }, children: "expand_less" })) : (_jsx("span", { className: "material-symbols-rounded", children: "expand_more" }))] }) }, `Users & Permissions`), _jsx(Collapse, { in: openUsersAndPermissions, timeout: "auto", unmountOnExit: true, children: _jsxs(List, { component: "div", disablePadding: true, children: [loggedInUserRolePermission &&
                                                loggedInUserRolePermission.length > 0 &&
                                                hasViewPermissionForModule(Module[Module.Users]) && (_jsx(NavLink, { to: "admin/users", className: ({ isActive }) => isActive
                                                    ? currentUser?.role.toLowerCase() ==
                                                        "superadmin" ||
                                                        currentUser?.role.toLowerCase() == "admin"
                                                        ? "active"
                                                        : "board-active"
                                                    : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: { pl: 4 }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "account_box" }) }), _jsx(ListItemText, { primary: translate("users.users"), sx: { whiteSpace: "nowrap" } })] }) }, "Users") })), loggedInUserRolePermission &&
                                                loggedInUserRolePermission.length > 0 &&
                                                hasViewPermissionForModule(Module[Module["Role Permissions"]]) && (_jsx(NavLink, { to: "admin/permissions", className: ({ isActive }) => isActive
                                                    ? currentUser?.role.toLowerCase() ==
                                                        "superadmin" ||
                                                        currentUser?.role.toLowerCase() == "admin"
                                                        ? "active"
                                                        : "board-active"
                                                    : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: { pl: 4 }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "key" }) }), _jsx(ListItemText, { primary: translate("permissions.permissions"), sx: { whiteSpace: "nowrap" } })] }) }, "Permissions") }))] }) })] }))] })] }) }));
}
export default Sidebar;
