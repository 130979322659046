import { wijzneusApi } from "./wijzneus-api";
import { convertModelToFormData } from "./helpers";
const getThemes = () => {
    return wijzneusApi.get(`themes`);
};
const getCoursesByThemeId = (request) => {
    return wijzneusApi.get(`themes/${request}/courses`);
};
const getThemeDropdownList = () => {
    return wijzneusApi.get(`themes/dropdown`);
};
const getTheme = (request) => {
    return wijzneusApi.get(`themes/${request}`);
};
const addTheme = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.postForm(`themes`, formData);
};
const updateTheme = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.putForm(`themes`, formData);
};
const enableDisableTheme = (request) => {
    return wijzneusApi.patch(`themes`, request);
};
const downloadAndViewTheme = (fileName) => {
    return wijzneusApi.get(`themes/${fileName}/download`);
};
const deleteTheme = (request) => {
    return wijzneusApi.delete(`themes/${request}`);
};
const ThemeApi = {
    getThemes,
    getTheme,
    addTheme,
    updateTheme,
    enableDisableTheme,
    downloadAndViewTheme,
    getThemeDropdownList,
    getCoursesByThemeId,
    deleteTheme,
};
export default ThemeApi;
