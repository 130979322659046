import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { enableDisableBoardAsync, getBoardsAsync, } from "@application/features/board/boardThunk";
import "./style.scss";
import WNSwitch from "@ui/components/wn-switch";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import WNLongMenu from "@ui/components/wn-long-menu";
import { formateDate } from "@application/common/helpers/date-time-helper";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
function Board() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const { boards } = useAppSelector((state) => state.board);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Boards]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getBoardsAsync());
    }, [dispatch]);
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableBoardAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getBoardsAsync());
            }
        });
    };
    const columns = [
        {
            field: "name",
            headerName: translate("boards.boardName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "totalSchools",
            headerName: translate("schools.schools").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "totalAgreements",
            headerName: translate("agreements.agreements").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 190 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "lastAgreementDate",
            headerName: translate("boards.lastAgreement").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 250 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${params.row.lastAgreementDate !== null
                ? formateDate(params.row.lastAgreementDate, DateFormatConstants.MDY)
                : ""}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            filterable: false,
            hideSortIcons: false,
            hideable: false,
            flex: 1,
            minWidth: isSmall ? 130 : undefined,
            cellClassName: "tableSwitchAlignCenter",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(_Fragment, { children: params.row.name == "Uncategorized" ? (_jsx(_Fragment, { children: _jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => { }, disable: true }, params.row.id) })) : (_jsx(_Fragment, { children: _jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.id) }, params.row.id) })) })),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            filterable: false,
            hideSortIcons: false,
            hideable: false,
            renderCell: (params) => (_jsx(_Fragment, { children: _jsx(WNLongMenu, { options: getOptions(params.row), onClick: (option) => onLongMenuClick(option, params.row) }, params.row.id) })),
        },
    ];
    const onLongMenuClick = (selectedOption, row) => {
        switch (selectedOption) {
            case "Details":
                navigate(`${row.id}`);
                break;
            case "ViewSchools":
                navigate(`/admin/boards/${row.id}/schools`);
                break;
            default:
                break;
        }
    };
    const getOptions = (row) => {
        const options = [
            ...(hasViewPermission || hasEditPermission
                ? [
                    {
                        key: "Details",
                        label: translate("cta.details"),
                        route: "Details",
                        isActive: row.name === "Uncategorized" ? false : true,
                    },
                ]
                : []),
            ...(hasViewPermission
                ? [
                    {
                        key: "ViewSchools",
                        label: translate("cta.viewSchools"),
                        route: "ViewSchools",
                        isActive: true,
                    },
                ]
                : []),
        ];
        return options;
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("boards.boardsOfSchools") }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/boards/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: boards ?? [], columns: columns, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !boards?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                            "": (hasEditPermission || hasViewPermission) ?? true,
                        } }, "board-grid") }) })] }));
}
export default Board;
