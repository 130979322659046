import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import SeasonApi from "@infrastructure/season-api";
export const getSeasonDropdownAsync = createAsyncThunk("season/getSeasons", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SeasonApi.getSeasonsDropdown();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
