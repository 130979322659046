import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, Box, Button, Grid, TextField, Typography, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { addErrorNotification } from "@application/features/common/notificationSlice";
import { useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import { getDownloadAndViewNewsAsync, getNewsByIdAsync, updateNewsAsync, } from "@application/features/news/newsThunk";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getPortalDropdownAsync } from "@application/features/portal/portalThunk";
import dayjs from "dayjs";
import { PermissionType } from "@application/common/enums/permission-type";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { PortalTypeStatusMap } from "@application/common/enums/portal-type";
const EditNews = () => {
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const { type } = useParams();
    const dispatch = useAppDispatch();
    const [imageFile, setImageFile] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { portalDropdownList } = useAppSelector((state) => state.portal);
    const { singleNews } = useAppSelector((state) => state.news);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.News]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getPortalDropdownAsync());
    }, [dispatch]);
    useEffect(() => {
        if (type) {
            fetchDataAndDispatch();
        }
    }, [dispatch, type]);
    const fetchDataAndDispatch = async () => {
        try {
            // Dispatch an action
            if (type) {
                await dispatch(getNewsByIdAsync(type));
                setLoading(true);
            }
            // The following code will run after the dispatch is completed
            console.log("Dispatch completed!");
            // Your additional logic here
        }
        catch (error) {
            console.error("Error during dispatch:", error);
        }
    };
    const [selectedImage, setSelectedImage] = useState(null);
    const { register, setValue, getValues, control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            title: "",
            portalIds: [],
            publishDate: undefined,
            videoLink: "",
            description: "",
            picture: undefined,
        },
    });
    const onSubmit = (credentials) => {
        if (type)
            credentials.id = type;
        credentials.picture = selectedImage ?? undefined;
        dispatch(updateNewsAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/news");
            }
        });
    };
    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files && event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                setSelectedImage(selectedFile);
            }
            else {
                dispatch(addErrorNotification(translate("validations.fileNotImage")));
                return;
            }
        }
    };
    useEffect(() => {
        if (singleNews && loading) {
            setValue("title", singleNews.title);
            setValue("portalIds", singleNews.portals);
            setValue("publishDate", singleNews.publishDate);
            setValue("videoLink", singleNews.videoLink);
            setValue("description", singleNews.description);
            const fileName = singleNews.picture.split("/")[1];
            dispatch(getDownloadAndViewNewsAsync(fileName)).then((res) => {
                if (res.payload !== null &&
                    res.payload !== undefined &&
                    res.payload !== "") {
                    const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                    setImageFile(base64Data);
                }
            });
        }
    }, [dispatch, singleNews, setValue, loading]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: hasEditPermission
                            ? translate("cta.editModule").replace("{module}", translate("news.news"))
                            : translate("cta.viewModule").replace("{module}", translate("news.news")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs(Grid, { container: true, spacing: 2.5, justifyContent: "space-between", children: [_jsx(Grid, { item: true, lg: 3, md: 3, xs: 12, children: _jsxs("div", { className: "image-box theme", children: [_jsx("div", { className: "image-container", children: _jsx("img", { src: selectedImage
                                                ? URL.createObjectURL(selectedImage)
                                                : imageFile
                                                    ? imageFile
                                                    : "", alt: "" }) }), _jsx("input", { type: "file", ref: fileInputRef, style: { display: "none" }, onChange: handleFileChange }), hasEditPermission && (_jsx(Button, { className: "Button", variant: "outlined", color: "primary", sx: {
                                            width: "100%",
                                            borderTopLeftRadius: "0",
                                            borderTopRightRadius: "0",
                                        }, onClick: handleButtonClick, children: translate("cta.upload") })), _jsx(Typography, { variant: "h6", textAlign: "center", marginTop: 0.5, marginBottom: 1, children: translate("news.newsImage") })] }) }), _jsx(Grid, { item: true, lg: 9, md: 9, xs: 12, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "news-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 4, md: 4, xs: 12, children: _jsx(TextField, { id: "title", label: translate("news.newsTitle"), variant: "outlined", size: "small", placeholder: translate("news.newsTitle"), type: "text", InputLabelProps: { shrink: true }, error: !!errors.title, helperText: errors.title?.message, fullWidth: true, disabled: !hasEditPermission, ...register("title", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("news.newsTitle")),
                                                    },
                                                }) }) }), _jsx(Grid, { item: true, lg: 4, md: 4, xs: 12, children: portalDropdownList && (_jsx(Controller, { name: "portalIds", control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("news.targetPortals")),
                                                    },
                                                }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", multiple: true, filterSelectedOptions: true, options: portalDropdownList, value: value, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionLabel: (option) => PortalTypeStatusMap.get(option.portalType), disabled: !hasEditPermission, onChange: (_, value) => {
                                                        onChange(value ?? []);
                                                    }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("news.targetPortals"), placeholder: translate("news.targetPortals"), error: errors.portalIds ? true : false, helperText: errors.portalIds?.message })) })) })) }), _jsx(Grid, { item: true, lg: 4, md: 4, xs: 12, children: _jsx(Controller, { name: "publishDate", control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("news.publishDate")),
                                                    },
                                                }, render: ({ field }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(DatePicker, { slotProps: {
                                                            textField: {
                                                                size: "small",
                                                                fullWidth: true,
                                                                error: !!errors.publishDate,
                                                                helperText: errors.publishDate?.message,
                                                            },
                                                            field: {
                                                                readOnly: true,
                                                            },
                                                        }, format: DateFormatConstants.MDY, label: translate("news.publishDate"), value: getValues("publishDate") === undefined
                                                            ? null
                                                            : dayjs(getValues("publishDate")), disabled: !hasEditPermission, onChange: (value) => setValue("publishDate", value.toDate()), minDate: dayjs() }) })) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { id: "videoLink", label: translate("news.videoLink"), variant: "outlined", size: "small", placeholder: translate("news.videoLink"), type: "text", InputLabelProps: { shrink: true }, helperText: errors.videoLink?.message, error: !!errors.videoLink, fullWidth: true, disabled: !hasEditPermission, ...register("videoLink", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("news.videoLink")),
                                                    },
                                                    pattern: {
                                                        value: /^(http|https):\/\/[^ "]+$/,
                                                        message: translate("validations.invalidUrl"),
                                                    },
                                                }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { id: "description", label: translate("common.description"), variant: "outlined", size: "small", placeholder: translate("common.description"), type: "text", InputLabelProps: { shrink: true }, helperText: errors.description?.message, error: !!errors.description, fullWidth: true, disabled: !hasEditPermission, ...register("description", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("common.description")),
                                                    },
                                                }) }) }), hasEditPermission && (_jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.saveChanges") }) }))] }) }) })] }) })] }));
};
export default EditNews;
