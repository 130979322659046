import { wijzneusApi } from './wijzneus-api';
const getGoals = () => {
    //const filter = `&filter=${request.filter ? request.filter : ''}`;
    return wijzneusApi.get(`goals`);
};
const getSubGoalByGoalId = (request) => {
    return wijzneusApi.get(`goals/${request}/subgoals`);
};
const getGoal = (request) => {
    return wijzneusApi.get(`goals/${request}`);
};
const getGoalDropdown = () => {
    return wijzneusApi.get(`goals/dropdown`);
};
const addGoal = (request) => {
    return wijzneusApi.post(`goals`, request);
};
const updateGoal = (request) => {
    return wijzneusApi.put(`goals`, request);
};
const enableDisableGoal = (request) => {
    return wijzneusApi.patch(`goals`, request);
};
const GoalApi = {
    getGoals,
    getGoal,
    getGoalDropdown,
    addGoal,
    updateGoal,
    enableDisableGoal,
    getSubGoalByGoalId
};
export default GoalApi;
