import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import * as Highcharts from "highcharts";
import { translate } from "@application/common/helpers/translate";
export default function DashboardRecentSchools({ data, }) {
    useEffect(() => {
        Highcharts.setOptions({
            credits: {
                enabled: false, // Disable the credits link
            },
        });
        Highcharts.chart("container", {
            chart: {
                backgroundColor: "transparent",
            },
            title: {
                text: "",
            },
            legend: {
                enabled: false,
            },
            xAxis: {
                categories: data.map(({ date }) => date),
                title: {
                    text: translate("dashboard.date"),
                },
            },
            yAxis: {
                title: {
                    text: translate("schools.schools"),
                },
            },
            tooltip: {
                formatter: function () {
                    return `<b>${translate("schools.schools")}:</b> ${this.y}`;
                },
            },
            series: [
                {
                    type: "line",
                    data: data.map(({ count }) => count),
                },
            ],
        });
    }, [data]);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", sx: { mb: 1 }, children: translate("dashboard.schoolsPast4Weeks") }), _jsx("div", { id: "container", style: { width: "100%", height: "180px" } })] }));
}
