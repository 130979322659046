import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getPortalDropdownAsync } from './portalThunk';
const initialState = {
    status: APIStatus.IDLE,
    portalDropdownList: []
};
export const portalSlice = createSlice({
    name: 'portal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPortalDropdownAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getPortalDropdownAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.portalDropdownList = action.payload;
        })
            .addCase(getPortalDropdownAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = portalSlice.actions;
export default portalSlice.reducer;
