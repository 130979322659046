import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { addBoardsAsync, enableDisableBoardAsync, getBoardByIdAsync, getBoardsAsync, getBoardsDropdownAsync, getSchoolsByBoardIdAsync, getSchoolsDropdownAsync, updateBoardsAsync } from './boardThunk';
import { AppConsts } from '@application/common/constants/app-consts';
const boardsRequest = {
    pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
};
const initialState = {
    status: APIStatus.IDLE,
    boardRequest: boardsRequest,
    boardDropdownList: [],
    schoolDropdownList: [],
    schoolList: [],
};
export const boardSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {
        setBoardRequest(state, action) {
            state.boardRequest = action.payload;
        },
        resetSchoolsDropdown(state, action) {
            state.schoolDropdownList = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBoardsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getBoardsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.boards = action.payload;
        })
            .addCase(getBoardsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getBoardsDropdownAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getBoardsDropdownAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.boardDropdownList = action.payload;
        })
            .addCase(getBoardsDropdownAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSchoolsByBoardIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSchoolsByBoardIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.schoolList = action.payload;
        })
            .addCase(getSchoolsByBoardIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSchoolsDropdownAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSchoolsDropdownAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.schoolDropdownList = action.payload;
        })
            .addCase(getSchoolsDropdownAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getBoardByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getBoardByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.board = action.payload;
        })
            .addCase(getBoardByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addBoardsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addBoardsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addBoardsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateBoardsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateBoardsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateBoardsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableBoardAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableBoardAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableBoardAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { setBoardRequest, resetSchoolsDropdown } = boardSlice.actions;
export default boardSlice.reducer;
