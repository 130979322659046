import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from "@application/common/enums/api-status";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { RoleEnum } from "@application/common/enums/role";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { getBoardsDropdownAsync, getSchoolsDropdownAsync, } from "@application/features/board/boardThunk";
import { addErrorNotification } from "@application/features/common/notificationSlice";
import { getRoleDropDownAsync } from "@application/features/role/roleThunk";
import { getUserByIdAsync, updateUserAsync, } from "@application/features/user/userThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
const EditUser = () => {
    const dispatch = useAppDispatch();
    const { type } = useParams();
    const navigate = useNavigate();
    const { user, status } = useAppSelector((state) => state.user);
    const { roleDropdownList } = useAppSelector((state) => state.role);
    const [loading, setLoading] = useState(false);
    const { boardDropdownList, schoolDropdownList, status: dropdownStatus, } = useAppSelector((state) => state.board);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Users]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const [selectedRole, setSelectedRole] = useState(user?.role?.label);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const { register, formState: { errors }, control, setError, setValue, handleSubmit, } = useForm({
        defaultValues: {
            ...user,
        },
    });
    useEffect(() => {
        dispatch(getRoleDropDownAsync());
        dispatch(getBoardsDropdownAsync(true));
        dispatch(getSchoolsDropdownAsync(false));
    }, []);
    useEffect(() => {
        if (type) {
            dispatch(getUserByIdAsync(type)).then((res) => {
                setLoading(true);
            });
        }
    }, [dispatch, type]);
    useEffect(() => {
        if (user && user.role && loading) {
            setValue("id", user.id);
            setValue("firstName", user.firstName);
            setValue("lastName", user.lastName);
            setValue("email", user.email);
            setValue("phoneNumber", user.phoneNumber);
            setValue("roleId", user.role.id);
            setSelectedRole(user.role.label);
            setValue("boardId", user.boardId);
            dispatch(getSchoolsDropdownAsync(false)).then((res) => {
                if (Array.isArray(res.payload)) {
                    setSelectedSchool({
                        ...(res.payload?.filter((x) => x.id.toLocaleLowerCase() === user.schoolId)[0] ?? null),
                    });
                    setValue("schoolId", user.schoolId);
                }
                else {
                    dispatch(addErrorNotification("Error fetching schools."));
                }
            });
        }
    }, [user, loading]);
    const onSubmit = (credentials) => {
        credentials.id = type ?? "";
        if (selectedSchool?.id != null) {
            credentials.boardId = selectedSchool.boardId;
            credentials.schoolId = selectedSchool.id;
        }
        else {
            credentials.schoolId = null;
        }
        dispatch(updateUserAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/users");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: hasEditPermission
                            ? translate("cta.editModule").replace("{module}", translate("users.user"))
                            : translate("cta.viewModule").replace("{module}", translate("users.user")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "user-form", noValidate: true, children: status == APIStatus.IDLE && (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 6, children: [_jsx(TextField, { id: "first-name", label: translate("account.firstName"), variant: "outlined", size: "small", placeholder: translate("account.firstName"), type: "text", fullWidth: true, disabled: !hasEditPermission ||
                                                        (user &&
                                                            user.role &&
                                                            user.role.label == RoleEnum[RoleEnum.SuperAdmin]), InputLabelProps: { shrink: true }, error: !!errors.firstName, ...register("firstName", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.firstName")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.firstName })] }), _jsxs(Grid, { item: true, xs: 6, children: [_jsx(TextField, { id: "last-name", label: translate("account.lastName"), variant: "outlined", size: "small", placeholder: translate("account.lastName"), type: "text", fullWidth: true, disabled: !hasEditPermission ||
                                                        (user &&
                                                            user.role &&
                                                            user.role.label == RoleEnum[RoleEnum.SuperAdmin]), InputLabelProps: { shrink: true }, error: !!errors.lastName, ...register("lastName", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.lastName")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.lastName })] }), _jsxs(Grid, { item: true, xs: 6, children: [_jsx(TextField, { id: "email", label: translate("account.email"), disabled: !hasEditPermission ||
                                                        (user &&
                                                            user.role &&
                                                            user.role.label == RoleEnum[RoleEnum.SuperAdmin]), variant: "outlined", size: "small", placeholder: translate("account.emailPlaceholder"), type: "email", error: !!errors.email, InputLabelProps: {
                                                        shrink: true,
                                                    }, fullWidth: true, ...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.email")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                        pattern: {
                                                            value: /^(?!.*\.\.)[A-Z0-9]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: translate("validations.invalidEmailAddress"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.email })] }), _jsxs(Grid, { item: true, xs: 6, children: [_jsx(TextField, { id: "phoneNumber", label: translate("account.phoneNumber"), variant: "outlined", size: "small", placeholder: translate("account.phoneNumber"), fullWidth: true, disabled: !hasEditPermission ||
                                                        (user &&
                                                            user.role &&
                                                            user.role.label == RoleEnum[RoleEnum.SuperAdmin]), InputLabelProps: { shrink: true }, error: !!errors.phoneNumber, ...register("phoneNumber", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.phoneNumber")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.phoneNumber })] }), _jsxs(Grid, { item: true, xs: 6, children: [roleDropdownList && (_jsx(Controller, { name: "roleId", control: control, rules: {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.role")),
                                                        },
                                                    }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: roleDropdownList, value: roleDropdownList.find((option) => option.id === value) || null, isOptionEqualToValue: (option, value) => option.id === value.id, disabled: !hasEditPermission ||
                                                            (user &&
                                                                user.role &&
                                                                user.role.label ==
                                                                    RoleEnum[RoleEnum.SuperAdmin]), getOptionLabel: (option) => option.roleDisplayName, onChange: (_, value) => {
                                                            setSelectedRole(value?.label ?? "");
                                                            setValue("boardId", null);
                                                            setValue("schoolId", null);
                                                            setSelectedSchool(null);
                                                            onChange(value?.id ?? "");
                                                        }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("account.role"), placeholder: translate("account.role"), error: errors.roleId ? true : false })) })) })), _jsx(WnValidation, { fieldError: errors.roleId })] }), selectedRole == RoleEnum[RoleEnum.Board] && (_jsxs(Grid, { item: true, xs: 6, children: [boardDropdownList && (_jsx(Controller, { name: "boardId", control: control, rules: {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("boards.board")),
                                                        },
                                                    }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: boardDropdownList, value: boardDropdownList.find((option) => option.id === value) || null, isOptionEqualToValue: (option, value) => option.id === value.id, onChange: (_, value) => {
                                                            onChange(value?.id ?? null);
                                                        }, disabled: !hasEditPermission ||
                                                            (user &&
                                                                user.role &&
                                                                user.role.label ==
                                                                    RoleEnum[RoleEnum.SuperAdmin]), renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("boards.board"), placeholder: translate("boards.board"), error: errors.boardId ? true : false })) })) })), _jsx(WnValidation, { fieldError: errors.boardId })] })), (selectedRole === RoleEnum[RoleEnum.School] ||
                                            selectedRole === RoleEnum[RoleEnum.Teacher] ||
                                            selectedRole === RoleEnum[RoleEnum.Supervisor]) && (_jsxs(Grid, { item: true, xs: 6, children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedSchool, disabled: !hasEditPermission ||
                                                        (user &&
                                                            user.role &&
                                                            user.role.label == RoleEnum[RoleEnum.SuperAdmin]), onChange: (_, newValue) => {
                                                        setSelectedSchool(newValue);
                                                        setValue("schoolId", newValue?.id);
                                                        if (newValue !== null && newValue !== undefined) {
                                                            setError("schoolId", {
                                                                type: "required",
                                                                message: undefined,
                                                            });
                                                        }
                                                    }, groupBy: (option) => option.boardName, options: schoolDropdownList, isOptionEqualToValue: (option, value) => option.id === value?.id, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("schools.school"), placeholder: translate("schools.school"), variant: "outlined", ...register("schoolId", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("schools.school")),
                                                            },
                                                        }), error: errors.schoolId && selectedSchool === null
                                                            ? true
                                                            : false })) }), _jsx(WnValidation, { fieldError: errors.schoolId })] }))] }) }), hasEditPermission &&
                                user &&
                                user.role &&
                                user.role.label != RoleEnum[RoleEnum.SuperAdmin] && (_jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.saveChanges") }) }) }) }))] })) }) })] }));
};
export default EditUser;
