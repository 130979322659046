import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { addThemeAsync, deleteThemeAsync, enableDisableThemeAsync, getCoursesByThemeIdAsync, getDownloadAndViewThemeAsync, getThemeByIdAsync, getThemeDropdownListAsync, getThemesAsync, updateThemeAsync, } from "./themeThunk";
const initialState = {
    status: APIStatus.IDLE,
    themes: [],
    themeDropdownList: [],
    courseList: [],
};
export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getThemesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getThemesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.themes = action.payload;
        })
            .addCase(getThemesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getThemeDropdownListAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getThemeDropdownListAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.themeDropdownList = action.payload;
        })
            .addCase(getThemeDropdownListAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getCoursesByThemeIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getCoursesByThemeIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.courseList = action.payload;
        })
            .addCase(getCoursesByThemeIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getThemeByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getThemeByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.theme = action.payload;
        })
            .addCase(getThemeByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getDownloadAndViewThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getDownloadAndViewThemeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.themeFile = action.payload;
        })
            .addCase(getDownloadAndViewThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addThemeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateThemeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableThemeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(deleteThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(deleteThemeAsync.fulfilled, (state) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(deleteThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    },
});
export const {} = themeSlice.actions;
export default themeSlice.reducer;
