import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppConsts } from "@application/common/constants/app-consts";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { newRolePermissions } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { addRolePermissionsAsync } from "@application/features/role-permission/rolePermissionThunk";
import { useAppDispatch } from "@application/store/useStore";
import { Box, Button, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GoBack from "@ui/components/wn-go-back";
import WnNoRecordFound from "@ui/components/wn-no-record";
import WNSwitch from "@ui/components/wn-switch";
import WnValidation from "@ui/components/wn-validation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const AddRolePermission = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [rolePermissions, setRolePermissions] = useState(newRolePermissions() ?? []);
    const columns = [
        {
            field: "module",
            headerName: "MODULES",
            sortable: true,
            flex: 1,
            hideable: false,
        },
        {
            field: "viewPermission",
            headerName: "VIEW",
            sortable: false,
            flex: 1,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.viewPermission, disable: params.row.createPermission ||
                    params.row.editPermission ||
                    params.row.deletePermission ||
                    params.row.module === Module[Module.Dashboard], onChange: ($event) => handlePermissionChange(PermissionType[PermissionType.viewPermission], params.row.module) }, params.row.id)),
        },
        {
            field: "createPermission",
            headerName: "CREATE",
            sortable: false,
            flex: 1,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.createPermission, disable: params.row.editPermission || params.row.deletePermission, onChange: ($event) => handlePermissionChange(PermissionType[PermissionType.createPermission], params.row.module) }, params.row.id)),
        },
        {
            field: "editPermission",
            headerName: "EDIT",
            sortable: false,
            flex: 1,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.editPermission, disable: params.row.deletePermission, onChange: ($event) => handlePermissionChange(PermissionType[PermissionType.editPermission], params.row.module) }, params.row.id)),
        },
        {
            field: "deletePermission",
            headerName: "DELETE",
            sortable: false,
            flex: 1,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.deletePermission, onChange: ($event) => handlePermissionChange(PermissionType[PermissionType.deletePermission], params.row.module) }, params.row.id)),
        },
    ];
    const { register, formState: { errors }, handleSubmit, } = useForm({});
    const handlePermissionChange = (permissionKey, module) => {
        const moduleIndex = rolePermissions?.findIndex((permission) => permission.module === module);
        if (moduleIndex !== -1 && rolePermissions !== undefined) {
            setRolePermissions((prevPermissions) => {
                const updatedPermissions = [...prevPermissions];
                const editPermission = PermissionType[PermissionType.editPermission];
                const createPermission = PermissionType[PermissionType.createPermission];
                const viewPermission = PermissionType[PermissionType.viewPermission];
                switch (permissionKey) {
                    case PermissionType[PermissionType.deletePermission]:
                        updatedPermissions[moduleIndex] = {
                            ...updatedPermissions[moduleIndex],
                            [permissionKey]: !prevPermissions[moduleIndex][permissionKey],
                            [editPermission]: !prevPermissions[moduleIndex][permissionKey],
                            [createPermission]: !prevPermissions[moduleIndex][permissionKey],
                            [viewPermission]: module !== Module[Module.Dashboard]
                                ? !prevPermissions[moduleIndex][permissionKey]
                                : prevPermissions[moduleIndex][viewPermission],
                        };
                        break;
                    case PermissionType[PermissionType.editPermission]:
                        updatedPermissions[moduleIndex] = {
                            ...updatedPermissions[moduleIndex],
                            [permissionKey]: !prevPermissions[moduleIndex][permissionKey],
                            [createPermission]: !prevPermissions[moduleIndex][permissionKey],
                            [viewPermission]: module !== Module[Module.Dashboard]
                                ? !prevPermissions[moduleIndex][permissionKey]
                                : prevPermissions[moduleIndex][viewPermission],
                        };
                        break;
                    case PermissionType[PermissionType.createPermission]:
                        updatedPermissions[moduleIndex] = {
                            ...updatedPermissions[moduleIndex],
                            [permissionKey]: !prevPermissions[moduleIndex][permissionKey],
                            [viewPermission]: module !== Module[Module.Dashboard]
                                ? !prevPermissions[moduleIndex][permissionKey]
                                : prevPermissions[moduleIndex][viewPermission],
                        };
                        break;
                    case PermissionType[PermissionType.viewPermission]:
                        updatedPermissions[moduleIndex] = {
                            ...updatedPermissions[moduleIndex],
                            [permissionKey]: !prevPermissions[moduleIndex][permissionKey],
                        };
                        break;
                }
                return updatedPermissions;
            });
        }
    };
    const onSubmit = (credentials) => {
        credentials.roleClaims = rolePermissions;
        dispatch(addRolePermissionsAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/rolepermissions");
            }
        });
    };
    return (_jsx(_Fragment, { children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "role-permission-form", noValidate: true, children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("permissions.permission")) }) }) }), _jsxs(Box, { sx: {
                        marginTop: "1rem",
                        borderRadius: "12px",
                        backgroundColor: "#fff",
                        padding: "1rem",
                    }, children: [_jsx(Box, { sx: {
                                marginBottom: "1rem",
                            }, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsxs(Grid, { item: true, xs: 12, children: [_jsx(TextField, { id: "roleName", label: "Role Name", variant: "outlined", size: "small", placeholder: "Enter name", type: "text", fullWidth: true, error: !!errors.roleName, ...register("roleName", {
                                                required: {
                                                    value: true,
                                                    message: "Role name is required.",
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message: "Maximum 100 characters are allowed.",
                                                },
                                            }) }), _jsx(WnValidation, { fieldError: errors.roleName })] }) }) }), _jsxs("div", { style: { width: "100%" }, children: [_jsx(DataGrid, { autoHeight: true, rows: rolePermissions ?? [], columns: columns, getRowId: (row) => row.module, initialState: {
                                        pagination: {
                                            paginationModel: {
                                                pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                            },
                                        },
                                    }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !rolePermissions?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" } }, "role-grid"), _jsx(Box, { marginTop: 1, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) }) }) })] })] })] }) }));
};
export default AddRolePermission;
