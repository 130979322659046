import { wijzneusApi } from './wijzneus-api';
import { convertModelToFormData } from './helpers';
const getCourses = () => {
    return wijzneusApi.get(`courses`);
};
const getCourse = (request) => {
    return wijzneusApi.get(`courses/${request}`);
};
const addCourse = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.postForm(`courses`, formData);
};
const updateCourse = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.putForm(`courses`, formData);
};
const enableDisableCourse = (request) => {
    return wijzneusApi.patch(`courses`, request);
};
const downloadAndViewCourse = (fileName) => {
    return wijzneusApi.get(`courses/${fileName}/download`);
};
const CourseApi = {
    getCourses,
    getCourse,
    addCourse,
    updateCourse,
    enableDisableCourse,
    downloadAndViewCourse
};
export default CourseApi;
