import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
const WNSwitch = ({ checked, onChange, label, style, disable, isTimeOutEnabled = true }) => {
    const [isClicking, setIsClicking] = useState(false);
    const handleSwitchChange = () => {
        if (!isClicking) {
            setIsClicking(true);
            onChange(!checked);
            setTimeout(() => {
                setIsClicking(false);
            }, 2000);
        }
    };
    const handleSwitchChangeForSpecificCase = () => {
        onChange(!checked);
    };
    return (_jsx(FormControlLabel, { style: style, disabled: disable, control: _jsx(Switch, { className: "admin-switch", checked: checked, onChange: () => isTimeOutEnabled ? handleSwitchChange() : handleSwitchChangeForSpecificCase() }), label: label ?? null }));
};
export default WNSwitch;
