const AUTH_TOKEN = 'AUTH_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const TWOFACTOR = 'TWO_FACTOR';
const setAccessToken = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
};
const setRefreshToken = (token) => {
    localStorage.setItem(REFRESH_TOKEN, token);
};
const setTwoFactor = (twoFactor) => {
    localStorage.setItem(TWOFACTOR, twoFactor);
};
const getAccessToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
};
const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
};
const getTwoFactor = () => {
    return localStorage.getItem(TWOFACTOR);
};
const removeAccessToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
};
const removeRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN);
};
const removeTwoFactor = () => {
    localStorage.removeItem(TWOFACTOR);
};
const LocalStorageService = {
    setAccessToken,
    setRefreshToken,
    setTwoFactor,
    getAccessToken,
    getRefreshToken,
    getTwoFactor,
    removeAccessToken,
    removeRefreshToken,
    removeTwoFactor
};
export default LocalStorageService;
