import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { addGoalAsync, enableDisableGoalAsync, getGoalByIdAsync, getGoalDropdownAsync, getGoalsAsync, getSubGoalsByGoalIdAsync, updateGoalAsync } from './goalThunk';
const initialState = {
    status: APIStatus.IDLE,
    goals: [],
    goalDropdownList: [],
    subGoalList: []
};
export const goalSlice = createSlice({
    name: 'goal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGoalsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getGoalsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.goals = action.payload;
        })
            .addCase(getGoalsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getGoalDropdownAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getGoalDropdownAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.goalDropdownList = action.payload;
        })
            .addCase(getGoalDropdownAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSubGoalsByGoalIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSubGoalsByGoalIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.subGoalList = action.payload;
        })
            .addCase(getSubGoalsByGoalIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getGoalByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getGoalByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.goal = action.payload;
        })
            .addCase(getGoalByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addGoalAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addGoalAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addGoalAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateGoalAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateGoalAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateGoalAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableGoalAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableGoalAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableGoalAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = goalSlice.actions;
export default goalSlice.reducer;
