import { wijzneusApi } from './wijzneus-api';
import { convertModelToFormData } from './helpers';
const getNews = () => {
    return wijzneusApi.get(`news`);
};
const getAdminPortalNews = () => {
    return wijzneusApi.get(`news/GetNewsForAdmin`);
};
const getSingleNews = (request) => {
    return wijzneusApi.get(`news/${request}`);
};
const addNews = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.postForm(`news`, formData);
};
const updateNews = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.putForm(`news`, formData);
};
const enableDisableNews = (request) => {
    return wijzneusApi.patch(`news`, request);
};
const downloadAndViewNews = (fileName) => {
    return wijzneusApi.get(`news/${fileName}/download`);
};
const NewsApi = {
    getNews,
    getSingleNews,
    addNews,
    updateNews,
    enableDisableNews,
    downloadAndViewNews,
    getAdminPortalNews
};
export default NewsApi;
