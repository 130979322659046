import { wijzneusApi } from "./wijzneus-api";
const login = (credentials) => {
    return wijzneusApi.post("auth/login", credentials);
};
const getRefreshToken = (refreshToken) => {
    return wijzneusApi.post("token/refresh", refreshToken);
};
const verifyToken = (refreshToken) => {
    return wijzneusApi.post("token/verify-token", refreshToken);
};
const signUp = (user) => {
    return wijzneusApi.post("auth/register", user);
};
const confirmEmail = (confirmation) => {
    return wijzneusApi.post("auth/email-confirmation", confirmation);
};
const forgotPassword = (input) => {
    return wijzneusApi.post("auth/forgot-password", input);
};
const resetPassword = (input) => {
    return wijzneusApi.post("auth/reset-password", input);
};
const twoFactorLoginAsync = (input) => {
    return wijzneusApi.post("auth/login-twofactor", input);
};
const forcedPasswordChanged = (input) => {
    return wijzneusApi.post("auth/forced-password-changed", input);
};
const userForgotPassword = (input) => {
    return wijzneusApi.post("auth/user-forgot-password", input);
};
const AuthApi = {
    login,
    getRefreshToken,
    signUp,
    confirmEmail,
    forgotPassword,
    resetPassword,
    verifyToken,
    twoFactorLoginAsync,
    forcedPasswordChanged,
    userForgotPassword,
};
export default AuthApi;
