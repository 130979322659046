import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getAdminPortalFaqsAsync } from "@application/features/faq/faqThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme, } from "@mui/material";
import { useEffect } from "react";
const BoardDashboardFaqs = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { faq } = useAppSelector((state) => state.faq);
    useEffect(() => {
        dispatch(getAdminPortalFaqsAsync());
    }, [dispatch]);
    return (_jsx(_Fragment, { children: faq &&
            faq.faqItems?.map((faqItem) => (_jsxs(Accordion, { sx: { borderRadius: "0 !important" }, children: [_jsx(AccordionSummary, { expandIcon: _jsx("span", { className: "material-symbols-rounded", children: "expand_more" }), "aria-controls": `panel-${faqItem.id}-content`, id: `panel-${faqItem.id}-header`, children: _jsx(Typography, { variant: "h6", color: theme.palette.primary.main, children: faqItem.question }) }), _jsx(AccordionDetails, { children: _jsx(Typography, { children: faqItem.answer }) })] }, faqItem.id))) }));
};
export default BoardDashboardFaqs;
