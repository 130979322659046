import moment from "moment";
export const generateUniqueNumberByDate = () => {
    return moment(new Date()).format("YYYYMMDDHHmmss");
};
export const agGridDateFormatter = (params) => {
    return moment(params.value, "DD/MM/YYYY").format("MM/DD/YYYY");
};
export const getMonthStartAndEndDate = (mode) => {
    const currentDate = new Date();
    if (mode === 'year') {
        const endDate = new Date(currentDate.getFullYear() + '-12-31');
        const startDate = new Date((currentDate.getFullYear() - 2) + '-01-01');
        return { start: startDate, end: endDate };
    }
    else {
        const startDate = getMinDateByEndDate(currentDate, 2, "years");
        return { start: startDate, end: currentDate };
    }
};
export const getMaxDateByStartDate = (startDate, modeOffset, mode) => {
    return moment(startDate).add(modeOffset, mode).endOf("month").toDate();
};
export const getMinDateByEndDate = (endDate, modeOffset, mode) => {
    if (mode === 'year') {
        return moment(endDate).subtract(modeOffset, mode).startOf(mode).toDate();
    }
    else {
        return moment(endDate).subtract(modeOffset, mode).toDate();
    }
};
export const getMonthDifference = (date1, date2) => {
    const monthsApart = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
    return Math.abs(monthsApart);
};
export const getDateDifference = (startDate, endDate, mode) => {
    //return moment(endDate).diff(startDate, mode);
    return moment(endDate).diff(startDate, mode);
};
export const getDateDifferenceInMonth = (startDate, endDate, mode) => {
    return moment(endDate).diff(startDate, mode);
};
export const formateDate = (date, format) => {
    return moment(date).format(format);
};
