import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { addAgreementAsync, getAgreementByIdAsync, getAgreementsAsync, getDownloadAndViewAgreementAsync, updateAgreementAsync } from './agreementThunk';
const initialState = {
    status: APIStatus.IDLE,
    agreements: []
};
export const agreementSlice = createSlice({
    name: 'agreement',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAgreementsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getAgreementsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.agreements = action.payload;
        })
            .addCase(getAgreementsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getAgreementByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getAgreementByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.agreement = action.payload;
        })
            .addCase(getAgreementByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getDownloadAndViewAgreementAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getDownloadAndViewAgreementAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.agreementFile = action.payload;
        })
            .addCase(getDownloadAndViewAgreementAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addAgreementAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addAgreementAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addAgreementAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateAgreementAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateAgreementAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateAgreementAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = agreementSlice.actions;
export default agreementSlice.reducer;
