import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./style.scss";
import logo from "../../../../logo.svg";
import loginImage from "../../../../login-feature-nose.png";
import { Alert, Box, Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, TextField, Typography, } from "@mui/material";
import React, { useEffect } from "react";
import { loginAsync } from "@application/features/auth/authThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { translate } from "@application/common/helpers/translate";
import WnValidation from "@ui/components/wn-validation";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { clearApiError } from "@application/features/auth/authSlice";
function Login() {
    const { currentUser, apiError } = useAppSelector((state) => state.auth);
    const [showPassword, setShowPassword] = React.useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        if (currentUser) {
            navigate("/admin/dashboard");
        }
    }, [currentUser, navigate]);
    // useEffect(() => {
    //     if (currentUser) {
    //         if (['Admin', 'SuperAdmin', 'Board', 'School'].includes(currentUser.role)) navigate('/admin/dashboard');
    //     }
    // }, [currentUser, navigate]);
    // useEffect(() => {
    //     if (currentUser) {
    //         if (currentUser.role === 'User') {
    //             navigate('/');
    //         }
    //     }
    // }, [currentUser, navigate]);
    useEffect(() => {
        dispatch(clearApiError());
    }, []);
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            email: "",
            password: "",
            rememberMe: false,
        },
    });
    const onSubmit = (credentials) => dispatch(loginAsync(credentials));
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: logo, className: "login-logo", height: 90, alt: "logo" }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "fields", children: _jsxs(Container, { sx: { width: "80%" }, children: [_jsx(Typography, { variant: "h4", sx: {
                                        fontWeight: "700",
                                        color: "winter.main",
                                        marginBottom: "40px",
                                    }, children: translate("account.loginToWijzneus") }), apiError && apiError.status === 401 && (_jsxs(_Fragment, { children: [_jsx(Alert, { severity: "error", children: apiError.error }), _jsx("br", {})] })), _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "login-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 4, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Controller, { name: "email", control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.email")),
                                                            },
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: translate("validations.invalidEmailAddress"),
                                                            },
                                                        }, render: ({ field: { onChange, value } }) => (_jsx(TextField, { size: "small", InputLabelProps: { shrink: true }, type: "email", id: "email", label: translate("account.email"), variant: "outlined", placeholder: translate("account.emailPlaceholder"), onChange: onChange, value: value, sx: { width: "100%" } })) }), _jsx(WnValidation, { fieldError: errors.email })] }), _jsxs(Grid, { item: true, xs: 12, children: [_jsxs(FormControl, { variant: "outlined", size: "small", sx: { width: "100%" }, children: [_jsx(InputLabel, { shrink: true, htmlFor: "outlined-adornment-password", children: translate("account.password") }), _jsx(Controller, { name: "password", control: control, rules: {
                                                                    required: {
                                                                        value: true,
                                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("account.password")),
                                                                    },
                                                                }, render: ({ field: { onChange, value } }) => (_jsx(OutlinedInput, { notched: true, id: "outlined-adornment-password", type: showPassword ? "text" : "password", name: "password", placeholder: translate("account.password"), onChange: onChange, value: value, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end", children: showPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }), label: translate("account.password") })) }), _jsx(WnValidation, { fieldError: errors.password })] }), _jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", mt: 1 }, children: _jsx(Link, { component: "button", variant: "body2", onClick: () => navigate("/forget-password"), sx: { display: "block", textAlign: "right" }, children: translate("account.forgotPassword") }) })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Button, { className: "Button", variant: "contained", color: "customDark", disableElevation: true, size: "large", sx: { width: "100%" }, type: "submit", children: translate("account.login") }) })] }) })] }) }), _jsx("div", { className: "feature", children: _jsx("img", { src: loginImage, style: { maxWidth: 400 }, height: "85%", alt: "feature" }) })] })] }));
}
export default Login;
