import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import PortalApi from "@infrastructure/portal-api";
export const getPortalDropdownAsync = createAsyncThunk("portal/getPortalDropdown", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await PortalApi.getPortalDropdown();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
