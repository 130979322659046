import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseApi from "@infrastructure/course-api";
import { addSuccessNotification } from "../common/notificationSlice";
import { translate } from "@application/common/helpers/translate";
export const getCoursesAsync = createAsyncThunk("course/getCourses", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await CourseApi.getCourses();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getCourseByIdAsync = createAsyncThunk("course/getCourse", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await CourseApi.getCourse(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getDownloadAndViewCourseAsync = createAsyncThunk("course/getDownloadAndViewCourse", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await CourseApi.downloadAndViewCourse(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableCourseAsync = createAsyncThunk("course/enableDisableCourse", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await CourseApi.enableDisableCourse(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("lessons.lesson"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addCourseAsync = createAsyncThunk("course/addCourse", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await CourseApi.addCourse(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("lessons.lesson"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateCourseAsync = createAsyncThunk("course/updateCourse", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await CourseApi.updateCourse(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("lessons.lesson"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
