import { translate } from "../helpers/translate";
export var CourseItemType;
(function (CourseItemType) {
    CourseItemType[CourseItemType["ExternalLink"] = 0] = "ExternalLink";
    CourseItemType[CourseItemType["UploadAttachment"] = 1] = "UploadAttachment";
})(CourseItemType || (CourseItemType = {}));
export const LanguageStatusMap = new Map([
    [CourseItemType.ExternalLink, translate("enums.courseItemType.externalLink")],
    [
        CourseItemType.UploadAttachment,
        translate("enums.courseItemType.uploadAttachment"),
    ],
]);
