import { translate } from "../helpers/translate";
export var PortalType;
(function (PortalType) {
    PortalType[PortalType["KidsPortal"] = 0] = "KidsPortal";
    PortalType[PortalType["CMS"] = 1] = "CMS";
    PortalType[PortalType["Website"] = 2] = "Website";
})(PortalType || (PortalType = {}));
export const PortalTypeStatusMap = new Map([
    [PortalType.KidsPortal, translate("enums.portalType.kidsPortal")],
    [PortalType.CMS, translate("enums.portalType.cms")],
    [PortalType.Website, translate("enums.portalType.website")],
]);
