import { wijzneusApi } from './wijzneus-api';
const getSubGoals = () => {
    return wijzneusApi.get(`subgoals`);
};
const getSubGoal = (request) => {
    return wijzneusApi.get(`subgoals/${request}`);
};
const getSubGoalDropdownList = () => {
    return wijzneusApi.get(`subgoals/dropdown`);
};
const addSubGoal = (request) => {
    return wijzneusApi.post(`subgoals`, request);
};
const updateSubGoal = (request) => {
    return wijzneusApi.put(`subgoals`, request);
};
const enableDisableSubGoal = (request) => {
    return wijzneusApi.patch(`subgoals`, request);
};
const GoalApi = {
    getSubGoals,
    getSubGoal,
    addSubGoal,
    updateSubGoal,
    enableDisableSubGoal,
    getSubGoalDropdownList
};
export default GoalApi;
