import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from "@emotion/react";
import "./style.scss";
import { Outlet } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useAppSelector } from "@application/store/useStore";
const customColors = {
    winter: {
        main: "#2D8AC5",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    inputBg: {
        main: "#F7F7F7",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#000",
    },
    customDark: {
        main: "#343233",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    white: {
        main: "#fff",
        light: "#fff",
        dark: "#fff",
        contrastText: "#000",
    }
};
const AuthLayout = () => {
    const { isToggle } = useAppSelector((state) => state.toggle);
    const theme = createTheme({
        palette: {
            // mode: 'dark',
            primary: {
                main: "#2D8AC5",
                light: "#42a5f5",
                dark: "#1565c0",
                contrastText: "#fff",
            },
            winter: {
                main: customColors.winter.main,
                light: customColors.winter.light,
                dark: customColors.winter.dark,
                contrastText: customColors.winter.contrastText,
            },
            inputBg: {
                main: customColors.inputBg.main,
                light: customColors.inputBg.light,
                dark: customColors.inputBg.dark,
                contrastText: customColors.inputBg.contrastText,
            },
            customDark: {
                main: customColors.customDark.main,
                light: customColors.customDark.light,
                dark: customColors.customDark.dark,
                contrastText: customColors.customDark.contrastText,
            },
            white: {
                main: customColors.white.main,
                light: customColors.white.light,
                dark: customColors.white.dark,
                contrastText: customColors.white.contrastText,
            },
            secondary: {
                main: "#ffba00",
            },
        },
        shape: {
            borderRadius: 20,
        },
        typography: {
            fontFamily: '"Work Sans", sans-serif',
            button: {
                fontSize: "1rem",
                fontWeight: "normal"
            },
        },
        // Override button hover color
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        whiteSpace: "nowrap",
                        "&:hover": {
                            backgroundColor: "winter.main",
                        },
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        borderRadius: 20,
                    }
                }
            }
        },
    });
    return (_jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx("div", { className: "login-container", children: _jsx("div", { className: "login-outer", children: _jsx(Outlet, {}) }) })] }));
};
export default AuthLayout;
