import { wijzneusApi } from './wijzneus-api';
import { convertModelToFormData } from './helpers';
const getAgreements = () => {
    return wijzneusApi.get(`agreements`);
};
const getAgreement = (request) => {
    return wijzneusApi.get(`agreements/${request}`);
};
const addAgreement = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.postForm(`agreements`, formData);
};
const updateAgreement = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.putForm(`agreements`, formData);
};
const downloadAndViewAgreement = (fileName) => {
    return wijzneusApi.get(`agreements/${fileName}/download`);
};
const AgreementApi = {
    getAgreements,
    getAgreement,
    addAgreement,
    updateAgreement,
    downloadAndViewAgreement,
};
export default AgreementApi;
