import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import NewsApi from "@infrastructure/news-api";
import { translate } from "@application/common/helpers/translate";
export const getNewsAsync = createAsyncThunk("news/getNews", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await NewsApi.getNews();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getAdminPortalNewsAsync = createAsyncThunk("news/getAdminPortalNews", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await NewsApi.getAdminPortalNews();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getNewsByIdAsync = createAsyncThunk("news/getSingleNews", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await NewsApi.getSingleNews(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addNewsAsync = createAsyncThunk("news/addNews", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await NewsApi.addNews(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("news.news"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateNewsAsync = createAsyncThunk("news/updateNews", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await NewsApi.updateNews(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("news.news"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableNewsAsync = createAsyncThunk("news/enableDisableNews", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await NewsApi.enableDisableNews(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("news.news"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getDownloadAndViewNewsAsync = createAsyncThunk("news/getDownloadAndViewNews", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await NewsApi.downloadAndViewNews(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
