import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { addFaqAsync, updateFaqAsync, enableDisableFaqAsync, getFaqByPortalAsync, getFaqsAsync, deleteFaqAsync, getAdminPortalFaqsAsync } from "./faqThunk";
const initialState = {
    status: APIStatus.IDLE,
};
export const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFaqsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getFaqsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.faqs = action.payload;
        })
            .addCase(getFaqsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getFaqByPortalAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getFaqByPortalAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.faq = action.payload;
        })
            .addCase(getFaqByPortalAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getAdminPortalFaqsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getAdminPortalFaqsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.faq = action.payload;
        })
            .addCase(getAdminPortalFaqsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addFaqAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addFaqAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addFaqAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateFaqAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateFaqAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateFaqAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableFaqAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableFaqAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableFaqAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(deleteFaqAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(deleteFaqAsync.fulfilled, (state) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(deleteFaqAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    },
});
export const {} = faqSlice.actions;
export default faqSlice.reducer;
