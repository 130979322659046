import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import './style.scss';
import { IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
const GoBack = ({ title, id }) => {
    const navigate = useNavigate();
    return _jsxs(_Fragment, { children: [_jsx(IconButton, { color: "primary", size: "large", onClick: () => navigate(-1), children: _jsx(ArrowBack, {}) }), _jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: title })] });
};
export default GoBack;
