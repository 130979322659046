import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import UserApi from "@infrastructure/user-api";
import { translate } from "@application/common/helpers/translate";
export const getUsersAsync = createAsyncThunk("user/getUsers", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await UserApi.getUsers();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserByIdAsync = createAsyncThunk("user/getUser", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await UserApi.getUser(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addUserAsync = createAsyncThunk("user/addUser", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await UserApi.addUser(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("users.user"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateUserAsync = createAsyncThunk("user/updateUser", async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await UserApi.updateUser(request);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("users.user"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateUserPasswordAsync = createAsyncThunk("user/updateUserPassword", async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await UserApi.updateUserPassword(request);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("users.user"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableUserAsync = createAsyncThunk("user/enableDisableUser", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await UserApi.enableDisableUser(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("users.user"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
