import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from '@application/common/enums/api-status';
import { getDashboardDataAsync } from "./dashboardThunk";
const initialState = {
    status: APIStatus.IDLE,
};
export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardDataAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getDashboardDataAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.dashboard = action.payload;
        })
            .addCase(getDashboardDataAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;
