import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import "./style.scss";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { enableDisableGoalAsync, getGoalsAsync, } from "@application/features/goal/goalThunk";
import { useEffect } from "react";
import WNSwitch from "@ui/components/wn-switch";
import { DataGrid } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import WNLongMenu from "@ui/components/wn-long-menu";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
const Goals = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { goals } = useAppSelector((state) => state.goal);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Goals]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getGoalsAsync());
    }, [dispatch]);
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableGoalAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getGoalsAsync());
            }
        });
    };
    const options = [
        ...(hasViewPermission || hasEditPermission
            ? [
                {
                    key: "Details",
                    label: translate("cta.details"),
                    route: "Details",
                    isActive: true,
                },
            ]
            : []),
        ...(hasViewPermission
            ? [
                {
                    key: "ViewSubGoals",
                    label: translate("cta.viewSubGoals"),
                    route: "ViewSubGoals",
                    isActive: true,
                },
            ]
            : []),
    ];
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "name",
            headerName: translate("goals.goalName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "totalSubGoals",
            headerName: translate("goals.totalSubGoals").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 230 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "totalCourses",
            headerName: translate("goals.currentCourses").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 230 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.id) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            renderCell: (params) => (_jsx(WNLongMenu, { options: options, onClick: (option) => onLongMenuClick(option, params.row) }, params.row.id)),
        },
    ];
    const onLongMenuClick = (selectedOption, row) => {
        switch (selectedOption) {
            case "Details":
                navigate(`${row.id}`);
                break;
            case "ViewSubGoals":
                navigate(`/admin/goals/${row.id}/subgoals`);
                break;
            default:
                break;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("goals.mainGoals") }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/goals/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, rows: goals ?? [], columns: columns, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !goals?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                            "": (hasEditPermission || hasViewPermission) ?? true,
                        } }, "goal-grid") }) })] }));
};
export default Goals;
