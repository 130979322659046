import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { addCourseAsync, getCourseByIdAsync, getCoursesAsync, getDownloadAndViewCourseAsync, updateCourseAsync } from './courseThunk';
const initialState = {
    status: APIStatus.IDLE,
    courses: [],
    featureImage: ''
};
export const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCoursesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getCoursesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.courses = action.payload;
        })
            .addCase(getCoursesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getCourseByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getCourseByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.course = action.payload;
        })
            .addCase(getCourseByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getDownloadAndViewCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getDownloadAndViewCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.featureImage = action.payload;
        })
            .addCase(getDownloadAndViewCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = courseSlice.actions;
export default courseSlice.reducer;
