import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from "@application/common/enums/api-status";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { getGoalByIdAsync, updateGoalAsync, } from "@application/features/goal/goalThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Box, Button, Grid, TextField } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
const EditGoal = () => {
    const dispatch = useAppDispatch();
    const { type } = useParams();
    const navigate = useNavigate();
    const { goal, status } = useAppSelector((state) => state.goal);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Goals]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const { register, setValue, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            name: "",
            description: "",
        },
    });
    useEffect(() => {
        if (type) {
            dispatch(getGoalByIdAsync(type));
        }
    }, [dispatch, type]);
    useEffect(() => {
        if (goal) {
            setValue("name", goal.name);
            setValue("description", goal.description);
        }
    }, [goal]);
    const onSubmit = (credentials) => {
        credentials.id = type ?? "";
        dispatch(updateGoalAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/goals");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: hasEditPermission
                            ? translate("cta.editModule").replace("{module}", translate("goals.goal"))
                            : translate("cta.viewModule").replace("{module}", translate("goals.goal")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "goal-form", noValidate: true, children: status == APIStatus.IDLE && (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 4, children: [_jsx(TextField, { id: "goalName", label: translate("goals.goalName"), variant: "outlined", size: "small", placeholder: translate("goals.goalName"), type: "text", fullWidth: true, disabled: !hasEditPermission, error: !!errors.name, InputLabelProps: { shrink: true }, ...register("name", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("goals.goalName")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.name })] }), _jsxs(Grid, { item: true, xs: 4, lg: 8, children: [_jsx(TextField, { id: "description", label: translate("common.description"), variant: "outlined", size: "small", placeholder: translate("common.description"), type: "text", fullWidth: true, disabled: !hasEditPermission, InputLabelProps: { shrink: true }, error: !!errors.description, ...register("description", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("common.description")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.description })] })] }) }), hasEditPermission && (_jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.saveChanges") }) }) }) }))] })) }) })] }));
};
export default EditGoal;
