import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
const ITEM_HEIGHT = 30;
const WNLongMenu = ({ options, onClick, isActive }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs("div", { children: [_jsx(IconButton, { "aria-label": "more", id: "long-button", "aria-controls": open ? "long-menu" : undefined, "aria-expanded": open ? "true" : undefined, "aria-haspopup": "true", color: "primary", onClick: handleClick, children: _jsx(MoreVertIcon, {}) }), _jsx(Menu, { id: "long-menu", MenuListProps: {
                    "aria-labelledby": "long-button",
                }, anchorEl: anchorEl, open: open, onClose: handleClose, PaperProps: {
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }, children: options.map((option) => (_jsxs("div", { children: [option.key === "Delete" && _jsx(Divider, {}), _jsx(MenuItem, { onClick: () => onClick(option.route), disabled: !option.isActive, children: option.label }, option.key)] }, option.key))) })] }));
};
export default WNLongMenu;
