import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Typography, useMediaQuery, useTheme, } from "@mui/material";
import "./style.scss";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import WNSwitch from "@ui/components/wn-switch";
import WNLongMenu from "@ui/components/wn-long-menu";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect } from "react";
import { getSchoolsAsync, updateSchoolStatusAsync, } from "@application/features/school/schoolThunk";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { formateDate } from "@application/common/helpers/date-time-helper";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
function Schools() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { schools } = useAppSelector((state) => state.school);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Schools]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const theme = useTheme();
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const islarge = useMediaQuery("(min-width:1201px) and (max-width:2700px)");
    const columns = [
        {
            field: "name",
            headerName: translate("schools.schoolName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
            hideable: false,
        },
        {
            field: "board",
            headerName: translate("boards.board").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 150 : undefined,
            valueGetter: (params) => params.row.board.label,
        },
        {
            field: "teachersCount",
            headerName: translate("teachers&Supervisors.teachers").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 150 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "agreementsCount",
            headerName: translate("agreements.agreements").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 215 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "lastAgreementDate",
            headerName: translate("schools.lastAgreement").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 253 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${params.row.lastAgreementDate !== null
                ? formateDate(params.row.lastAgreementDate, DateFormatConstants.MDY)
                : ""}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 100 : undefined,
            filterable: false,
            hideable: false,
            hideSortIcons: false,
            cellClassName: "tableSwitchAlignCenter",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, disable: !params.row.board.isActive, onChange: ($event) => onSchoolStatusUpdate({
                    id: params.row.id,
                    status: $event,
                }) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            filterable: false,
            minWidth: 100,
            hideable: false,
            hideSortIcons: false,
            renderCell: (params) => (_jsx(WNLongMenu, { options: options, onClick: (option) => onLongMenuClick(option, params.row) }, params.row.id)),
        },
    ];
    useEffect(() => {
        dispatch(getSchoolsAsync());
    }, [dispatch]);
    const onSchoolStatusUpdate = (updateSchoolStatusDto) => {
        dispatch(updateSchoolStatusAsync(updateSchoolStatusDto));
    };
    const onLongMenuClick = (selectedOption, row) => {
        switch (selectedOption) {
            case "Details":
                navigate(`${row.id}`);
                break;
            case "ViewTeachers":
                navigate(`/admin/schools/${row.id}/teachers`);
                break;
            default:
                break;
        }
    };
    const options = [
        ...(hasViewPermission || hasEditPermission
            ? [
                {
                    key: "Details",
                    label: translate("cta.details"),
                    route: "Details",
                    isActive: true,
                },
            ]
            : []),
        ...(hasViewPermission
            ? [
                {
                    key: "ViewTeachers",
                    label: translate("cta.viewTeachers"),
                    route: "ViewTeachers",
                    isActive: true,
                },
            ]
            : []),
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("schools.schools") }), hasCreatePermission && (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/schools/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx(DataGrid, { autoHeight: true, componentsProps: { filterPanel: { sx: { maxWidth: "90svw" } } }, rows: schools ?? [], columns: columns, initialState: {
                        pagination: {
                            paginationModel: {
                                pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                            },
                        },
                    }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: schools === undefined || schools?.length <= 0, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                        isActive: hasEditPermission ?? true,
                        "": (hasEditPermission || hasViewPermission) ?? true,
                    } }, "school-grid") })] }));
}
export default Schools;
