export var Module;
(function (Module) {
    Module[Module["Dashboard"] = 0] = "Dashboard";
    Module[Module["Boards"] = 1] = "Boards";
    Module[Module["Schools"] = 2] = "Schools";
    Module[Module["Teachers & Supervisors"] = 3] = "Teachers & Supervisors";
    Module[Module["Goals"] = 4] = "Goals";
    Module[Module["Sub-Goals"] = 5] = "Sub-Goals";
    Module[Module["Themes"] = 6] = "Themes";
    Module[Module["Lessons"] = 7] = "Lessons";
    Module[Module["Vouchers"] = 8] = "Vouchers";
    Module[Module["News"] = 9] = "News";
    Module[Module["FAQs"] = 10] = "FAQs";
    Module[Module["Agreements"] = 11] = "Agreements";
    //"Invoices",
    Module[Module["Role Permissions"] = 12] = "Role Permissions";
    Module[Module["Users"] = 13] = "Users";
})(Module || (Module = {}));
